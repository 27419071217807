<template>
  <div>
    <ReesterView v-if="isReester" />
    <ProviderView v-else />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useAuthModule } from "@/store";

import ReesterView from "./ReesterView.vue";
import ProviderView from "./ProviderView.vue";

const { getUserRole } = useAuthModule();
const userRole = computed(() => getUserRole);

const isReester = computed(() => userRole.value?.id?.includes("Reester"));
</script>
