<template>
  <div class="w-100">
    <SourceItem :sources="sources" />
  </div>
</template>

<script setup>
import { apiKeyData } from "./Form";
import SourceItem from "@/components/SourceItem.vue";

const sources = [
  {
    id: 1,
    name: "Базовый уровень доступа",
    checked: false,
    open: false,
    rights: [
      ["Список сотрудников", "Добавление сотрудника"],
      ["Добавление контакта", "Удаление контакта"],
    ],
  },
  {
    id: 2,
    name: "Функциональные роли ENUM",
    checked: false,
    open: false,
    rights: [
      ["Список сотрудников", "Добавление сотрудника"],
      ["Добавление контакта", "Удаление контакта"],
    ],
  },
];

const apiKey = apiKeyData;
const apiKeyForm = apiKey.form;
apiKeyForm.key.value = "String";
</script>
