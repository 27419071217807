<template>
  <div class="form-container">
    <h3 class="title">Информация о пользователе</h3>

    <EmployeeForm :form="form" />

    <ProgressBar
      :actionText="'Сохранить'"
      :count="progress.length"
      :total="totalRequired"
      :loading="loading"
      @add="edit"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { Form } from "./Forms/Form";
import { EmployeesAPI } from "@/api/EmployeesAPI";
import { useFormHandler } from "@/composables/useFormHandler";
import notifications from "@/helpers/notifications";
import ProgressBar from "@/components/ProgressBar";
import EmployeeForm from "./Forms/EmployeeForm.vue";
import progressCounters from "@/helpers/progressCounters";

const route = useRoute();
const loading = ref(false);
const form = Form();

const progress = computed(() => progressCounters.getProgress(form));
const totalRequired = computed(() => progressCounters.getTotalRequired(form));

onMounted(async () => {
  loading.value = true;
  await EmployeesAPI.getById(route.params.id)
    .then((res) => {
      const response = res.data;
      for (const item in response) {
        if (form[item]) {
          form[item].value = response[item];
        }
      }
    })
    .catch((err) => {
      notifications(err);
    })
    .finally(() => {
      loading.value = false;
    });
});

const { handleSubmit } = useFormHandler(loading);

const edit = () => {
  handleSubmit({
    form: form,
    progress: progress.value,
    totalRequired: totalRequired.value,
    apiMethod: EmployeesAPI.update,
    successMessage: "Пользователь успешно изменен",
    redirectPath: "/employees",
    id: route.params.id,
  });
};
</script>
