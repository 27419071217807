<template>
  <div class="form-container">
    <div class="top-line">
      <Tabs :tabs="tabs" />
    </div>

    <router-view v-slot="{ Component }" v-if="!loading">
      <transition>
        <component :is="Component" :form="form" />
      </transition>
    </router-view>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import Tabs from "@/components/Tabs.vue";
import { Form } from "./Forms/Form";

const form = Form();
const loading = ref(true);

onMounted(() => {
  form.role_name.value = "Системный администратор";
  form.role_description.value =
    "Роль расширяет полномочия пользователя до администратора ИБ";
  form.canAccessPermissions.value = true;
  form.role_owner.value = "Куликов Иван Владимирович";
  form.role_subdivision.value = "Техническая дирекция";
  form.role_job.value = "Руководитель отдела";

  loading.value = false;
});

const tabs = ref([
  { title: "Общие настройки", route: "settings" },
  { title: "Источник", route: "source" },
  { title: "Пользователи", route: "roles-employees" },
]);
</script>
