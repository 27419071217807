<template>
  <Modalka
    v-if="isShowModal"
    :loading="false"
    @close="$emit('close')"
    @add="employee ? edit() : add()"
    actionText="Добавить"
    :headerText="
      employee ? 'Редактировать пользователя' : 'Добавить пользователя'
    "
  >
    <template v-slot:body>
      <EmployeeForm :form="form" />
    </template>
  </Modalka>
</template>

<script setup>
import { ref, computed, onMounted, defineProps, defineEmits } from "vue";
import { Form } from "./Forms/Form";
import { EmployeesAPI } from "@/api/EmployeesAPI";
import { useFormHandler } from "@/composables/useFormHandler";
import { DepartmentsAPI } from "@/api/DepartmentsAPI";
import { PositionsAPI } from "@/api/PositionsAPI";
import progressCounters from "@/helpers/progressCounters";
import Modalka from "@/components/modals/Modalka.vue";
import EmployeeForm from "./Forms/EmployeeForm";
import { useRoute } from "vue-router";

const props = defineProps({
  isShowModal: {
    type: Boolean,
    required: true,
  },
  employee: {
    type: Object,
  },
});
const emit = defineEmits("close");

const loading = ref(false);
let form = Form();
const route = useRoute();

const departments = ref([]);
const positions = ref([]);

onMounted(() => {
  DepartmentsAPI.getAll().then((res) => {
    departments.value = res.data;
  });
  PositionsAPI.getAll().then((res) => {
    positions.value = res.data;
  });
});

const progress = computed(() => progressCounters.getProgress(form));
const totalRequired = computed(() => progressCounters.getTotalRequired(form));

const { handleSubmit } = useFormHandler(loading);

const add = async () => {
  try {
    let result = await handleSubmit({
      form: form,
      progress: progress.value,
      totalRequired: totalRequired.value,
      apiMethod: EmployeesAPI.add,
      successMessage: "Пользователь успешно добавлен",
      redirectPath: "/employees",
    });

    if (result.success) {
      emit("close");
      window.dispatchEvent(new CustomEvent("updateTable"));
    } else {
      console.error("Operation failed :>> ", result.error);
    }
  } catch (error) {
    console.error("Unexpected error :>> ", error);
  }
};

onMounted(async () => {
  if (!props.employee) {
    return;
  }
  form = props.employee;
});

const edit = () => {
  handleSubmit({
    form: form,
    progress: progress.value,
    totalRequired: totalRequired.value,
    apiMethod: EmployeesAPI.update,
    successMessage: "Пользователь успешно изменен",
    redirectPath: "/employees",
    id: route.params.id,
  });
};
</script>
