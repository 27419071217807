class ProgressCounters {
  progressCounter = (form) => {
    return Object.values(form).reduce((acc, item) => {
      if (
        item.validators?.includes("required") &&
        item.valid &&
        Object.keys(item.errors).length !== 0 &&
        item.value !== ""
      ) {
        acc.push(item);
      }
      return acc;
    }, []);
  };

  totalRequiredCounter = (form) => {
    return Object.values(form).reduce((acc, item) => {
      if (item.validators?.includes("required")) {
        acc.push(item);
      }
      return acc;
    }, []).length;
  };

  progressSteps = (step, form) => {
    return Object.values(form).reduce((acc, item) => {
      if (
        step === item.step &&
        item.validators?.includes("required") &&
        item.valid &&
        Object.keys(item.errors).length !== 0 &&
        item.value !== ""
      ) {
        acc.push(item);
      }
      return acc;
    }, []);
  };

  totalRequiredSteps = (step, form) => {
    return Object.values(form).reduce((acc, item) => {
      if (step === item.step && item.validators?.includes("required")) {
        acc.push(item);
      }
      return acc;
    }, []).length;
  };

  getProgress = (form) => {
    const progressCount = Object.values(form).reduce((acc, item) => {
      if (
        item.validators?.includes("required") &&
        item.valid &&
        Object.keys(item.errors).length !== 0 &&
        item.value !== ""
      ) {
        acc.push(item);
      }
      return acc;
    }, []);

    return progressCount;
  };

  getTotalRequired = (form) => {
    const count = Object.values(form).reduce((acc, item) => {
      if (item.validators?.includes("required")) {
        acc.push(item);
      }
      return acc;
    }, []).length;

    return count;
  };
}

export default new ProgressCounters();
