<template>
  <div class="card">
    <div class="card__header">
      <h3 class="title">{{ form.name.value }}</h3>
      <p class="subtitle">Табельный номер: <span>12345678</span></p>
    </div>
    <div class="card__body">
      <EmployeeForm :form="form" :translation="translation" />
    </div>
  </div>
  <DeleteModal
    :isShowModal="showDeleteModal"
    title="Удалить пользователя?"
    :subtitle="employees?.name"
    @close="closeDeleteModal"
    @delete="deleteItem"
  />
  <AddModal
    :isShowModal="showEditModal"
    @close="closeEditModal"
    :employee="form"
  />
</template>

<script setup>
import notifications from "@/helpers/notifications";
import { notify } from "@kyvg/vue3-notification";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import EmployeeForm from "../Forms/EmployeeForm.vue";
import { EmployeesAPI } from "@/api/EmployeesAPI";
import AddModal from "../AddModal.vue";
import { Form } from "../Forms/Form";
import { useRoute, useRouter } from "vue-router";
import { onMounted, ref } from "vue";

const form = Form();
const router = useRouter();
const route = useRoute();
const employees = ref();
const loading = ref(false);

const props = defineProps({
  showDeleteModal: {
    type: Boolean,
    default: false,
  },
  showEditModal: {
    type: Boolean,
    default: false,
  },
  closeEditModal: {
    type: Function,
  },
  closeDeleteModal: {
    type: Function,
  },
  translation: {
    type: Boolean,
    default: false,
  },
});

const deleteItem = () => {
  EmployeesAPI.delete(route.params.id)
    .then(() => {
      notify({
        type: "success",
        text: "Удалено",
      });

      window.dispatchEvent(new CustomEvent("updateTable"));
      router.push("/employees");
    })
    .catch((err) => {
      notifications(err);
    })
    .finally(() => {
      props.toggleDeleteModal();
    });
};

onMounted(async () => {
  loading.value = true;
  await EmployeesAPI.getById(route.params.id)
    .then((res) => {
      const response = res.data;
      for (const item in response) {
        if (form[item]) {
          form[item].value = response[item];
        }
      }
    })
    .catch((err) => {
      notifications(err);
    })
    .finally(() => {
      loading.value = false;
    });
});
</script>
