import { defineStore } from "pinia";
import { DefaultAPIInstance } from "@/api";

export const useAuthModule = defineStore("auth", {
  id: "auth",
  state: () => ({
    user: null,
    credentials: {
      token: localStorage.getItem("token") || null,
      userRole: null,
    },
    logginIn: !!localStorage.getItem("token"),
  }),
  getters: {
    getUserPermissions: () => ({}),
    getUserRole: () => ({}),
    // getUserPermissions: (state) =>
    //     state.user?.permissions ??
    //     JSON.parse(localStorage.getItem('permissions')),
    // getUserRole: (state) =>
    //     state.user?.role ?? JSON.parse(localStorage.getItem('role')),
  },
  actions: {
    setToken(payload) {
      const token = payload.data.token;
      this.credentials.token = token;
      localStorage.setItem("token", token);
      this.setLogginIn(true);
      DefaultAPIInstance.defaults.headers["authorization"] = `Bearer ${token}`;
    },
    setUserRole(userRole) {
      this.credentials.userRole = userRole;
    },
    deleteToken() {
      this.credentials.token = null;
      localStorage.removeItem("token");
      delete DefaultAPIInstance.defaults.headers["authorization"];
    },
    setLogginIn(state) {
      this.logginIn = state;
    },
    setUser(payload) {
      this.user = payload;
      const permissions = JSON.stringify(payload.permissions);
      const role = JSON.stringify(payload.role);
      localStorage.setItem("permissions", permissions);
      localStorage.setItem("role", role);
    },
    logout() {
      this.user = null;
      this.logginIn = false;
      this.credentials = {};
    },
  },
});
