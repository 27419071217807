<template>
  <!-- как использовать -->
  <!-- <FormSearch
        :options="servicesListByCities"
        :validation="{ value: true }"
        @onSelect="servicesFind"
        searchLabel="name"
        label="Название сервиса"
        placeholder="Название сервиса"
      /> -->
  <div class="search" @blur="isShow = false">
    <FormInput
      class="select"
      v-model="searchValue"
      :validation="validation"
      :label="label"
      :placeholder="placeholder"
      :size="size"
      @input="handleInput"
      @click="isShow = !isShow"
    />

    <div v-if="isShow" class="search-result">
      <div class="search-result__title">
        <span v-if="searchItems?.length >= 1"> Возможно вы ищите </span>
        <span v-else-if="searchValue?.length >= 1 && options">
          Ничего не найдено
        </span>
        <span v-else-if="options?.length < 1"> Список пуст </span>
      </div>
      <ul v-if="searchValue?.length >= 1 && options">
        <li
          v-for="(item, index) in searchItems"
          :key="index"
          @click="handleClick(item)"
          class="search-result__item"
        >
          <b>{{ item[searchLabel] }}</b>
        </li>
      </ul>
      <ul v-else-if="options">
        <li
          v-for="(item, index) in options"
          :key="index"
          @click="handleClick(item)"
          class="search-result__item"
        >
          <b>{{ item[searchLabel] }}</b>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { watchEffect, toRefs, ref } from "vue";

const props = defineProps({
  size: String,
  searchLabel: String,
  options: Array,
  label: String,
  placeholder: String,
  validation: [Object || Boolean],
  value: String,
});
const emit = defineEmits("onSelect");

const { options, searchLabel } = toRefs(props);
let searchValue = ref("");

watchEffect(() => {
  searchValue.value = props.value;
});

const isShow = ref(false);
const searchItems = ref([]);

const handleInput = () => {
  isShow.value = true;
  if (!searchValue.value) {
    searchItems.value = [];
  } else {
    filterOptions();
  }
};

const filterOptions = () => {
  searchItems.value = options?.value?.filter((item) =>
    item[searchLabel.value]
      ?.toLowerCase()
      .includes(searchValue.value?.toLowerCase())
  );
};

const handleClick = (item) => {
  searchValue.value = item[searchLabel.value];
  emit("onSelect", item);
  isShow.value = false;
  filterOptions();
};
</script>

<style scoped lang="scss">
.select:before {
  content: "";
  cursor: pointer;
  position: absolute;
  right: 15px;
  bottom: 17px;
  z-index: 99;
  width: 20px;
  height: 20px;
  background: url("@/assets/images/chevron-down.svg") no-repeat center center;
}
.search {
  position: relative;
  &-result {
    box-shadow: 0px 16px 24px rgba(19, 23, 35, 0.04),
      0px 2px 6px rgba(19, 23, 35, 0.04), 0px 0px 1px rgba(19, 23, 35, 0.04);
    border-radius: 8px;
    padding: 20px;
    position: absolute;
    z-index: 99;
    width: 100%;
    background: #fff;
    top: 85px;
    &__title {
      color: #8f97b1;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__item span {
      margin-left: 5px;
      color: #aeb6cf;
      flex: 1;
    }
    ul {
      margin-top: 15px;
      li {
        color: #3e445b;
        font-size: 16px;
        height: 36px;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0;
        transition: 0.3s;
        padding-left: 15px;
        &:hover {
          background: #f8f9fc;
          border-radius: 6px;
        }
      }
    }
  }
}
</style>
