<template>
  <div class="search">
    <svg
      width="24px"
      height="24px"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      class="v-icon"
    >
      <use href="#search" />
    </svg>
    <input
      type="text"
      :value="searchValue"
      @input="onInput"
      :placeholder="placeholder"
    />
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

defineProps({
  searchValue: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "Поиск по таблице",
  },
});

const emit = defineEmits(["update:searchValue"]);

const onInput = (event) => {
  emit("update:searchValue", event.target.value);
};
</script>
