<template>
  <div class="form-container">
    <h3 class="title">Информация о пользователе</h3>

    <EmployeeForm :form="form" />

    <ProgressBar
      :count="progress.length"
      :total="totalRequired"
      :loading="loading"
      @add="add"
    />
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { Form } from "./Forms/Form";
import { EmployeesAPI } from "@/api/EmployeesAPI";
import { useFormHandler } from "@/composables/useFormHandler";
import ProgressBar from "@/components/ProgressBar";
import EmployeeForm from "./Forms/EmployeeForm";
import progressCounters from "@/helpers/progressCounters";

const loading = ref(false);
const form = Form();

const progress = computed(() => progressCounters.getProgress(form));
const totalRequired = computed(() => progressCounters.getTotalRequired(form));

const { handleSubmit } = useFormHandler(loading);

const add = () => {
  handleSubmit({
    form: form,
    progress: progress.value,
    totalRequired: totalRequired.value,
    apiMethod: EmployeesAPI.add,
    successMessage: "Пользователь успешно добавлен",
    redirectPath: "/employees",
  });
};
</script>
