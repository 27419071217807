import { DefaultAPIInstance, LoginAPIInstance } from "@/api";

export const AuthAPI = {
  /**
   *
   * @param payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  login(payload) {
    return LoginAPIInstance.post("/auth/login", payload);
  },
  /**
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  getProfile() {
    return DefaultAPIInstance.get("/users/profile");
  },
  /**
   *
   * @param id
   * @returns {Promise<AxiosResponse<any>>}
   */
  getUserById(id) {
    return DefaultAPIInstance.get(`/users/${id}`);
  },
  /**
   *
   * @param email
   * @returns {Promise<AxiosResponse<any>>}
   */
  getUserByEmail(email) {
    return DefaultAPIInstance.get(`/users/${email}`);
  },
  /**
   *
   * @param id
   * @returns {Promise<AxiosResponse<any>>}
   */
  deactivate(id) {
    return DefaultAPIInstance.get(`/users/deactivate/${id}`);
  },
  /**
   *
   * @param code
   */
  confirm_code(payload) {
    return LoginAPIInstance.post("/auth/code", payload);
  },
  /**
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  logout() {
    return DefaultAPIInstance.post("/logout");
  },
  /**
   *
   * @param
   * @returns {Promise<AxiosResponse<any>>}
   */
  authUserById(userId) {
    // console.log('UserId for auth:', userId)
    return DefaultAPIInstance.post("/auth/auth-user", { userId });
  },
};
