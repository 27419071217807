<template>
  <div :class="`status-` + status?.type" class="status">
    {{ status?.msg }}
  </div>
</template>
<script setup>
import { toRefs } from "vue";

const props = defineProps({
  status: {
    default: null,
  },
});
const { status } = toRefs(props);
</script>
