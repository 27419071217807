<template>
  <Form :form="form" :isEditible=true />

</template>

<script setup>

import { FormData } from "./Forms/Form";

import Form from "./Forms/Form.vue";


const form = FormData();

</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars";
.btn {
  background: #5bc4d1;
  color: #fff;
  letter-spacing: 0.3px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 9px;
  width: 100%;
  height: 48px;
  transition: 0.3s;
}

.form-container{
  background: #FFFFFF;
  max-width: 804px;
  box-shadow: 0px 3px 6px 0px rgba(19, 23, 35, 0.04), 0px 0px 2px 0px rgba(19, 23, 35, 0.06), 0px 0px 1px 0px rgba(19, 23, 35, 0.04);
  padding: 32px;
    }



.password-block {
  display: flex;
  align-items: flex-end;
  column-gap: 24px;
  flex-wrap: wrap;
  .password-block__input {
    flex-basis: 65%;
    margin-bottom: 0;
  }
  .password-block__button {
    flex-basis: 30%;
  }
  .password-block__text {
    flex-basis: 100%;
    margin-top: 8px;
    margin-bottom: 24px;
    font-size: 14px;
    color: $theme500;
  }
}
</style>
