<template>
  <div class="content">
    <Loading v-if="loading" style="height: 100vh" />
    <div v-else>
      <div class="table-header">
        <SearchInput
          placeholder="Поиск по ФИО пользователя, Подразделению или Должности..."
        />
        <div class="button-container">
          <Button
            v-if="filtersData.length > 1"
            icon="pi pi-filter"
            label="Фильтры"
            outlined
            rounded
            @click="isShowFilters = true"
            style="margin-right: 32px"
          />

          <Button
            class="add-button"
            icon="pi pi-plus"
            label="Добавить пользователя"
            rounded
            @click="isShowModal = true"
          />
        </div>
      </div>
      <Table
        name="employees"
        :columns="columns"
        :data="employees"
        :loading="loading"
      >
        <template #actions="{ item }">
          <li
            @click="router.push(`/employees/edit/${item.id}?name=${item.name}`)"
          >
            Редактировать
          </li>
          <li @click="remove(employees, item.id, 'employees', '/employees')">
            Удалить
          </li>
        </template>
      </Table>
      <AdvancedFilter
        v-if="isShowFilters"
        :filterableColumns="filtersData"
        :filters="filters"
        @update:filters="filters = $event"
        @close="isShowFilters = false"
      />
      <AddModal :isShowModal="isShowModal" @close="isShowModal = false" />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { remove } from "@/composables/actions";
import { useRouter } from "vue-router";
import { EmployeesAPI } from "@/api/EmployeesAPI";
import AddModal from "./AddModal";
import Table from "@/components/table/Table";
import SearchInput from "@/components/table/SearchInput";
import AdvancedFilter from "@/components/table/AdvancedFilter";
import Loading from "@/components/Loading";
import Button from "primevue/button";

const router = useRouter();

const loading = ref(false);
const employees = ref([]);
const isShowModal = ref(false);
const isShowFilters = ref(false);

const fetchData = () => {
  loading.value = true;
  EmployeesAPI.getAll()
    .then((res) => {
      employees.value = res.data.map((item) => ({
        ...item,
        department_name: item.department?.name,
        position_name: item.position?.name,
      }));
      filtersData();
    })
    .finally(() => {
      loading.value = false;
    });
};

onMounted(() => {
  fetchData();
  window.addEventListener("updateTable", fetchData);
});

const filtersData = computed(() => {
  if (!employees.value.length) return [];

  return [
    {
      title: "Подразделение",
      data: [...new Set(employees.value.map((item) => item.department?.name))],
    },
    {
      title: "Должность",
      data: [...new Set(employees.value.map((item) => item.position?.name))],
    },
  ];
});

const columns = {
  id: { value: "ID" },
  name: { value: "ФИО пользователя" },
  department_name: { value: "Подразделение" },
  position_name: { value: "Должность" },
};

onUnmounted(() => {
  window.removeEventListener("updateTable", fetchData);
});
</script>
