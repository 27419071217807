import { notify } from "@kyvg/vue3-notification";

export const notifications = (errors) => {
  if (!errors.response) {
    return notify({
      type: "error",
      text: "Неопознанная ошибка",
    });
  }
  if (errors.response.data.statusCode === 403) {
    return notify({
      type: "error",
      text: "У вас не достаточно прав",
    });
  }
  const err = errors.response.data.message;
  if (!Array.isArray(err)) {
    return notify({
      type: "error",
      text: err,
    });
  }
  for (const messages of err) {
    notify({
      type: "error",
      text: messages,
    });
  }
};

export default notifications;
