<template>
  <div class="content">
    <Loading v-if="loading" style="height: 100vh" />
    <div v-else>
      <div class="table-header">
        <SearchInput
          placeholder="Поиск по Сервису, Учетной записи и Пользователю..."
        />
        <div class="button-container">
          <Button
            icon="pi pi-filter"
            label="Фильтры"
            outlined
            rounded
            @click="isShowFilters = true"
            style="margin-right: 32px"
          />

          <Button
            class="add-button"
            icon="pi pi-plus"
            label="Добавить учетную запись"
            rounded
            @click="router.push(`/accounts/add`)"
          />
        </div>
      </div>
      <Table
        name="accounts"
        :columns="columns"
        :data="filteredItems"
        :loading="loading"
      >
        <template #actions="{ item }">
          <li
            @click="router.push(`/accounts/edit/${item.id}?name=${item.name}`)"
          >
            Редактировать
          </li>
          <li @click="remove(employees, item.id, 'accounts', '/accounts')">
            Удалить
          </li>
        </template>
      </Table>
      <AdvancedFilter
        v-if="isShowFilters"
        :filterableColumns="filtersData"
        :filters="filters"
        @update:filters="filters = $event"
        @close="isShowFilters = false"
      />

    </div>
  </div>
  
</template>

<script setup>
import Table from "@/components/table/Table";
import Loading from "@/components/Loading";
import { onMounted, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { USER_STATUSES } from "@/enums/enum-status";
import SearchInput from "@/components/table/SearchInput";
import AdvancedFilter from "@/components/table/AdvancedFilter";
import Button from "primevue/button";

const loading = ref(false);

const router = useRouter();

const columns = {
  id: { value: "ID", sortable: true },
  service: { value: "Сервис", sortable: true },
  account: { value: "Учётная запись", sortable: true },
  fio: { value: "Пользователь", sortable: true },
  created_at: { value: "Дата создания", sortable: true },
  last_login: { value: "Последний вход", sortable: true },
  updated_at: { value: "Обновление", sortable: true },
  status: { value: "Статус", sortable: true },
};

const accounts = ref([
  {
    id: 1,
    service: "Поликлиника №12",
    account: "Poliklinika_12",
    fio: "Куликов Иван Владимирович",
    created_at: "12 апреля 2024",
    last_login: "23 мая 2024",
    updated_at: "10 июня 2024",
    status: "active",
  },
  {
    id: 2,
    service: "1С, Предприятие",
    account: "1C_Organization",
    fio: "Перелеска Ирина Витальевна",
    created_at: "02 марта 2024",
    last_login: "11 апреля 2024",
    updated_at: "05 июня 2024",
    status: "inactive",
  },
  {
    id: 3,
    service: "МОБ ГБ Ирида",
    account: "Irida_142",
    fio: "Галинская Наталья Викторовна",
    created_at: "12 апреля 2024",
    last_login: "23 мая 2024",
    updated_at: "10 июня 2024",
    status: "active",
  },
  {
    id: 4,
    service: "МОБ СОШ Искра",
    account: "Iscra_24_Mob",
    fio: "Попова Елена Владимировна",
    created_at: "30 января 2024",
    last_login: "16 марта 2024",
    updated_at: "29 мая 2024",
    status: "active",
  },
  {
    id: 5,
    service: "1С, Предприятие",
    account: "1C_Organization45",
    fio: "Дик Игорь Александрович",
    created_at: "30 января 2024",
    last_login: "16 марта 2024",
    updated_at: "29 мая 2024",
    status: "active",
  },
  {
    id: 6,
    service: "Поликлиника №26",
    account: "Poliklinika_12",
    fio: "Куликов Иван Владимирович",
    created_at: "02 марта 2024",
    last_login: "11 апреля 2024",
    updated_at: "05 июня 2024",
    status: "inactive",
  },
  {
    id: 7,
    service: "МОБ ГБ Ирида",
    account: "Irida_142",
    fio: "Маркин Игорь Каримович",
    created_at: "12 апреля 2024",
    last_login: "23 мая 2024",
    updated_at: "10 июня 2024",
    status: "active",
  },
  {
    id: 8,
    service: "МОБ СОШ Искра",
    account: "Iscra_24_Mob",
    fio: "Марченко Андрей Анатольевич",
    created_at: "30 января 2024",
    last_login: "16 марта 2024",
    updated_at: "29 мая 2024",
    status: "active",
  },
  {
    id: 9,
    service: "Поликлиника №26",
    account: "Poliklinika_12",
    fio: "Ефимова Ольга Алексеевна",
    created_at: "17 августа 2024",
    last_login: "11 сентября 2024",
    updated_at: "05 июня 2024",
    status: "active",
  },
  {
    id: 10,
    service: "1С, Предприятие",
    account: "1C_Organization45",
    fio: "Влас Алексей Александрович",
    created_at: "02 марта 2024",
    last_login: "11 апреля 2024",
    updated_at: "05 июня 2024",
    status: "active",
  },
]);

const filteredItems = computed(() => {
  return accounts.value.map((user) => ({
    ...user,
    status: USER_STATUSES[user.status],
  }));
});

onMounted(() => {
  loading.value = false;
});
</script>
