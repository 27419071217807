import { useAuthModule } from "@/store";

export default function permissions({ to, next }) {
  const store = useAuthModule;
  // const userPermissions = store.getUserPermissions;
  const user = store.logginIn;
  const routePermission = to.meta.permission;
  if (!routePermission) {
    return next();
  }

  // if (Array.isArray(routePermission)) {
  // for (const p of routePermission) {
  // const hasPermission =
  //   userPermissions?.find((item) => item.key === p) ?? false;
  const hasPermission = true;
  if (hasPermission) {
    return next();
  }
  // }
  // }

  // const hasPermission =
  //   userPermissions?.find((item) => item.key === routePermission) ?? false;

  if (!hasPermission && user) {
    return next({
      name: "noaccess",
    });
  }
  return next();
}
