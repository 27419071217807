<template>
  <div class="backgraund-img"></div>
  <div class="view">
    <div class="text text-bold">
      Вы находитесь в панели провайдера (оператора) сервисов Реестра сервисов.
    </div>

    <div class="text">
      Реестр сервисов – инновационный продукт, являющийся частью интеграционной
      платформы ООО «Современные технологии».
    </div>

    <div class="text text-task">
      Роль – администратор реестра сервисов. Задачи решаемые Реестром сервисов:
    </div>

    <div class="text text-bold">
      <div>
        1. Ведение сведений о сервисах (запись, редактирование, удаление).
      </div>

      <div>
        2. Ведение сведений о структуре сервиса (запись, редактирование,
        удаление, просмотр):
      </div>
    </div>
    <div class="block-links">
      <li>адреса серверов, обслуживающие сервис,</li>
      <li>протоколы и способы аутентификации при обращении к сервису,</li>
      <li>используемые структуры данных запросов к сервису,</li>
      <li>используемые структуры данных ответов от сервиса,</li>
      <li>используемые методы,</li>
      <li>Smart-контракты, используемые сервисом.</li>
    </div>
    <div class="text text-bold">
      <div>
        3. Идентификация сервисов при помощи системы с использованием типов и
        классов сервисов.
      </div>
      <div>
        4. Создание логики выполнения сервисов в зависимости от обстоятельств
        времени, места и ситуации, в которых находится
        <p>
          ваш клиент и с учётом взаимосвязей с сервисами других провайдеров
          (операторов) сервисов.
        </p>
      </div>
    </div>

    <div class="text text-bottom">
      Разрешённые действия для текущей роли включают возможности: <br />
      Создание, внесение, редактирование и просмотр в Реестре сервисов данных о:
    </div>

    <div class="block-links">
      <ul>
        <li>
          <router-link class="link" to="/profile"
            >провайдерах (операторах) сервиса</router-link
          >
          ,работником которого вы являетесь
        </li>

        <li>
          <router-Link class="link" to="/services">сервисе </router-Link> – его
          наименование и характеристика
        </li>

        <li>
          <router-link class="link" to="/providers-auth">
            провайдере авторизации
          </router-link>
          и
          <router-link class="link" to="/types-auth"
            >типе авторизации</router-link
          >
        </li>

        <li>
          <router-link class="link" to="/protocol-services"
            >протоколе</router-link
          >
          , который использует сервис для доступа к нему
        </li>

        <li>
          <router-link class="link" to="/methods">методе</router-link> и
          <router-link class="link" to="/smart-contracts"
            >Smart-контракте</router-link
          >
          сервиса
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.view {
  position: absolute;
  max-width: 1256px;
  margin-left: 4%;
  padding-bottom: 50px;
  display: inline;
}

.backgraund-img {
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url("@/assets/images/bg-home.png");
  background-size: cover;
  background-position: right;
}

.text {
  width: 100%;
  max-width: 1176px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: var(--light-theme-rich-black-fogra-29-tints-800, #3e445b);

  &-task {
    line-height: 42px;
    width: 500px;
  }

  &-bottom {
    width: 1000px;
  }

  &-type {
    line-height: 38px;
    width: 900px;
  }

  &-bold {
    font-weight: 700;
    font-weight: 700;
    line-height: 32px;
  }
}

p {
  margin-left: 16px;
}

ul {
  list-style-type: disc;
  margin-left: 18px;
}

.link {
  text-decoration: none;
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: var(--light-theme-sky-blue-crayola-tints-500, #5bc4d1);
}

.block-links {
  margin-left: 16px;
  margin-top: 8px;
  line-height: 32px;
  color: var(--light-theme-rich-black-fogra-29-tints-800, #3e445b);
}
</style>
