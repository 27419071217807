<template>
  <div class="two-factor">
    <div class="two-factor__title">
      Проверка<br />
      безопасности
    </div>
    <p class="two-factor__desc">
      Введите этот 6-значный код <b>{{ localCodeProp }}</b>
    </p>
    <form @submit.prevent="confirm">
      <div class="two-factor__code">
        <div v-for="n in 6" :key="n" class="two-factor__number">
          <input
            @keyup="nextNum"
            ref="num"
            maxlength="1"
            type="text"
            v-model="localCode[n]"
          />
        </div>
      </div>
      <div class="signin__submit">
        <FormButton
          label="Подтвердить"
          type="submit"
          :loading="loading"
          :disabled="isDisable"
        />
        <button v-if="!timer" @click="sendCode" type="button" class="btn_code">
          Получить код повторно
        </button>
        <span v-else>Получить код повторно через {{ timer }} сек.</span>
      </div>
    </form>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, computed } from "vue";
import { useAuthModule } from "@/store";
import { notify } from "@kyvg/vue3-notification";
import { AuthAPI } from "@/api/AuthAPI";
import { useRouter } from "vue-router";
import notifications from "@/helpers/notifications";

const props = defineProps({
  email: {
    type: String,
    default: "",
  },
  password: {
    type: String,
    default: "",
  },
  code: {
    type: String,
    default: "",
  },
});

const store = useAuthModule();
const router = useRouter();
const localCode = reactive({});
const localCodeProp = ref(props.code);
const loading = ref(false);
const num = ref(null);
const timer = ref(59);

const startTimer = () => {
  timer.value = timer.value - 1;
  if (timer.value === 0) {
    clearInterval(counter);
    return;
  }
};

const counter = setInterval(startTimer, 1000);

onMounted(() => {
  num.value[0].focus();
  notify({
    type: "success",
    text: `Для входа введите этот код ${props.code}`,
  });
  startTimer();
});

const isDisable = computed(() => Object.keys(localCode).length !== 6);

const nextNum = (event) => {
  event.target.parentNode.nextSibling.firstChild.focus();
};

const confirm = async () => {
  await AuthAPI.confirm_code({
    code: Number(Object.values(localCode).join("")),
    email: props.email,
    password: props.password,
  })
    .then((token) => {
      store.setToken(token);
      router.push("/employees"); //замена с "/"
    })
    .catch((err) => {
      notifications(err);
    });

  await AuthAPI.getProfile().then((res) => {
    store.setUser(res.data);
  });
};

const sendCode = () => {
  timer.value = 59;
  startTimer();
  AuthAPI.login({
    email: props.email,
    password: props.password,
  }).then((response) => {
    localCodeProp.value = response.data.code;
    notify({
      type: "success",
      text: `Мы отправили новый код ${localCodeProp.value}`,
    });
  });
};
</script>

<style scoped lang="scss">
.signin__submit span {
  color: #5bc4d1;
  letter-spacing: 0.3px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: block;
  margin-top: 24px;
}
.btn_code {
  color: #5bc4d1;
  letter-spacing: 0.3px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: block;
  margin-top: 24px;
}
.two-factor {
  &__title {
    font-weight: 700;
    font-size: 40px;
    line-height: 52px;
    color: #727a93;
  }
  &__desc {
    color: #575e76;
    letter-spacing: 0.01em;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    margin-top: 24px;
    b {
      font-weight: 700;
    }
  }
  &__code {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 12px;
    margin-top: 32px;
    margin-bottom: 32px;
  }
  &__number {
    input {
      width: 64px;
      height: 56px;
      outline: none;
      border: 1px solid #d1d8ec;
      border-radius: 6px;
      text-align: center;
      color: #3e445b;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      &:focus {
        border: 1px solid #70e0ee;
        box-shadow: 0px 0px 20px rgba(112, 224, 238, 0.08);
      }
    }
  }
}
@media (max-width: 550px) {
  .two-factor__title {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
  }
  .two-factor__code {
    gap: 6px;
  }
  .two-factor__number input {
    width: 46px;
    height: 43px;
  }
}
</style>
