<template>
  <h3 class="title">Общая информация</h3>
  <div class="item">
    <div class="label">Описание роли</div>
    <div class="text">{{ service?.description }}</div>
  </div>
  <div class="item">
    <label class="checkbox-block">
      <span> Можно запрашивать разрешение </span>
      <Checkbox
        v-model="service.canRequestPermission"
        :binary="true"
        :disabled="true"
      />
    </label>
  </div>
  <div class="item item--grid">
    <div>
      <div class="label">Владелец роли</div>
      <div class="text">{{ service?.owner }}</div>
    </div>
    <div>
      <div class="label">Подразделение</div>
      <div class="text">{{ service?.subdivision }}</div>
    </div>
  </div>
  <h3 class="title">Автоматически присвоена роль</h3>
  <div class="item item--box">
    <div>
      <div class="subtitle">{{ service?.role?.name }}</div>
      <div class="text">{{ service?.role?.job }}</div>
    </div>
  </div>
</template>

<script setup>
import { toRefs } from "vue";
import Checkbox from "primevue/checkbox";

const props = defineProps({
  service: {
    default: null,
  },
});

const { service } = toRefs(props);
</script>
