<template>
  <div class="card">
    <div class="card__header">
      <h3 class="title">Назначенные роли</h3>
    </div>
  </div>
  <div class="card">
    <div class="card__header">
      <h3 class="title">Задачи изменения ролей</h3>
    </div>
  </div>
  <div class="card">
    <div class="card__header">
      <h3 class="title">Ранее назначавшиеся роли</h3>
    </div>
  </div>
</template>
