<template>
  <div class="noaccess">
    <img src="@/assets/images/noaccess.jpg" alt="noaccess" />
    <div class="noaccess__title">Нет доступа!</div>
    <div class="noaccess__desc">У Вас нет прав посещать данный раздел.</div>
  </div>
</template>

<style scoped lang="scss">
.noaccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    font-weight: 700;
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  img {
    width: 300px;
  }
}
</style>
