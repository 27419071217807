import { DefaultAPIInstance } from "@/api";

export const EmployeesAPI = {
  add(payload) {
    return DefaultAPIInstance.post("/employees", payload);
  },

  update(payload, id) {
    return DefaultAPIInstance.patch(`/employees/${id}`, payload);
  },

  getAll() {
    return DefaultAPIInstance.get("/employees");
  },

  getById(id) {
    return DefaultAPIInstance.get(`/employees/${id}`);
  },

  delete(id) {
    return DefaultAPIInstance.delete(`/employees/${id}`);
  },

  ban(id) {
    return DefaultAPIInstance.get(`/employees/ban/${id}`);
  },

  unban(id) {
    return DefaultAPIInstance.get(`/employees/ban/${id}`);
  },
};
