<template>
  <div v-if="localForm" class="w-100">
    <h3 class="title">Укажите данные пользователя для присвоения роли</h3>

    <div class="item item--grid">
      <div>
        <FormSelect
          v-model="localForm.user_subdivision.value"
          :validation="localForm.user_subdivision"
          :items="subdivisions"
          label="Подразделение"
          placeholder="Выберите подразделение"
          class="select"
        />
        <span
          v-if="
            localForm.user_subdivision.touched &&
            localForm.user_subdivision.errors.required
          "
          class="field-group__error"
          >Поле 'Подразделение' обязательно к заполнению!</span
        >
      </div>
      <div>
        <FormSelect
          v-model="localForm.user_job.value"
          :validation="localForm.user_job"
          :items="jobs"
          label="Должность"
          placeholder="Выберите должность"
          class="select"
        />
        <span
          v-if="
            localForm.user_job.touched && localForm.user_job.errors.required
          "
          class="field-group__error"
          >Поле 'Должность' обязательно к заполнению!</span
        >
      </div>
    </div>
    <div class="item">
      <FormSelect
        v-model="localForm.user_name.value"
        :validation="localForm.user_name"
        :items="employees"
        label="Пользователь"
        placeholder="Выберите пользователя"
      />
      <span
        v-if="
          localForm.user_name.touched && localForm.user_name.errors.required
        "
        class="field-group__error"
        >Поле 'Пользователь' обязательно к заполнению!</span
      >
    </div>

    <div class="item--short">
      <FormButton
        label="Добавить пользователя"
        type="button"
        @submit="isShowModal = true"
      />
    </div>
  </div>

  <Modalka
    v-if="isShowModal"
    :loading="false"
    @close="isShowModal = false"
    @add="addUser"
    actionText="Добавить"
    :headerText="`Добавить пользователя`"
  >
    <template v-slot:body>
      <form class="modal-form">
        <div>
          <FormSelect
            v-model="localForm.role_subdivision.value"
            :validation="localForm.role_subdivision"
            :items="subdivisions"
            label="Подразделение"
            placeholder="Выберите подразделение"
          />
          <FormSelect
            v-model="localForm.role_subdivision.value"
            :validation="localForm.role_subdivision"
            :items="jobs"
            label="Должность"
            placeholder="Выберите должность"
          />
          <FormSelect
            v-model="localForm.role_subdivision.value"
            :validation="localForm.role_subdivision"
            :items="employees"
            label="Пользователь"
            placeholder="Выберите пользователя"
          />
        </div>
      </form>
    </template>
  </Modalka>
</template>

<script setup>
import { ref } from "vue";
import Modalka from "@/components/modals/Modalka";

// Определяем пропсы компонента
const props = defineProps({
  form: {
    type: Object,
    default: () => ({}),
  },
});

// Локальные реактивные переменные и функции
const localForm = ref(props.form);
const isShowModal = ref(false);

const employees = [
  { key: "Куликов Иван Владимирович", id: "1" },
  { key: "Перелеска Ирина Витальевна", id: "2" },
];
const subdivisions = ref([
  { id: "1", name: "Отдел Информационных Технологий" },
  { id: "2", name: "Бухгалтерия" },
  { id: "3", name: "Управление проектами" },
  { id: "4", name: "Техническая дирекция" },
  { id: "5", name: "Отдел качества" },
  { id: "6", name: "Экономическая безопасность" },
]);
const jobs = ref([
  { id: "1", name: "Администратор" },
  { id: "2", name: "Разработчик" },
  { id: "3", name: "Главный бухгалтер" },
  { id: "4", name: "Бухгалтер" },
  { id: "5", name: "Руководитель отдела" },
  { id: "6", name: "Инженер" },
]);

const addUser = () => {
  // Ваш код для добавления пользователя
};
</script>

<style scoped lang="scss">
.item {
  .select {
    margin-bottom: 0;
  }
}

button {
  border-radius: 9px;
  width: 182px;
  height: 48px;
  letter-spacing: 0.3px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  background: #5bc4d1;
  color: #fff;
  :disabled {
    opacity: 0;
  }
}

.modal-form {
  width: 100%;
}
</style>
