import ValidationsForm from "@/helpers/validations";
import { useForm } from "@/composables/form";

const { required } = ValidationsForm;

export function Form() {
  return useForm({
    role_name: {
      value: "",
      validators: { required },
      step: "step1",
    },
    role_description: {
      value: "",
      validators: { required },
      step: "step1",
    },
    canAccessPermissions: {
      value: "",
      validators: {},
      step: "step1",
    },
    role_owner: {
      value: "",
      validators: { required },
      step: "step1",
    },
    role_owner_subdivision: {
      value: "",
      validators: { required },
      step: "step1",
    },
    role_subdivision: {
      value: "",
      validators: { required },
      step: "step1",
    },
    role_job: {
      value: "",
      validators: { required },
      step: "step1",
    },

    source: {
      value: {},
      validators: {},
      step: "step2",
    },

    user_subdivision: {
      value: "",
      validators: { required },
      step: "step3",
    },

    user_job: {
      value: "",
      validators: { required },
      step: "step3",
    },
    user_name: {
      value: [],
      validators: { required },
      step: "step3",
    },
  }).form;
}

export const apiKeyData = useForm({
  key: {
    value: "",
    validators: { required },
  },
  value: {
    value: "",
    validators: { required },
  },
});

export const bearerData = useForm({
  login: {
    value: "",
    validators: { required },
  },
  password: {
    value: "",
    validators: { required },
  },
});

export const bearerToken = useForm({
  token: {
    value: "",
    validators: { required },
  },
});
