import { DefaultAPIInstance } from "@/api";

export const DepartmentsAPI = {
  add(payload) {
    return DefaultAPIInstance.post("/departments", payload);
  },

  update(payload, id) {
    return DefaultAPIInstance.patch(`/departments/${id}`, payload);
  },

  getAll() {
    return DefaultAPIInstance.get("/departments");
  },

  getById(id) {
    return DefaultAPIInstance.get(`/departments/${id}`);
  },

  delete(id) {
    return DefaultAPIInstance.delete(`/departments/${id}`);
  },
};
