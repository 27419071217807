<template>
  <div class="content">
    <Loading v-if="loading" style="height: 100vh" />
    <div v-else>
      <TabView style="margin-top: 32px">
        <TabPanel v-for="(tab, index) in tabs" :key="index" :header="tab.title">
          <component :is="tab.component" />
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import RolesList from "./TableTabs/RolesList.vue";
import RolesHierarchy from "./TableTabs/RolesHierarchy.vue";
import RolesCatalogs from "./TableTabs/RolesCatalogs.vue";

const tabs = ref([
  { title: "Список ролей", component: RolesList },
  { title: "Иерархия ролей", component: RolesHierarchy },
  { title: "Каталоги ролей", component: RolesCatalogs },
]);
</script>
<style lang="scss">
.content {
  .p-tabview .p-tabview-panels {
    background: #f9fbff;
    padding: 0rem;
  }
}

.p-tabview .p-tabview-nav {
  background: #f9fbff !important;
}

.table-header {
  margin-bottom: 32px !important;
}
</style>
