export const account = {
  service: "МОБ СОШ “Искра”",
  date: "29 мая 2024",
  update_period: "3 месяца",
  user: "Попова Елена Владимировна",
  block: false,
  role: "Базовая",
  permissions: "CN+Domain Employees, CN=Employees, DC=demo, DC=stand",
  password: "password",
};

export const services = [
  { key: "МОБ СОШ “Искра”", id: "1" },
  { key: "1С, Предприятие", id: "2" },
];

export const updatePeriods = [
  { key: "3 месяца", id: "3" },
  { key: "6 месяцев", id: "6" },
  { key: "12 месяцев", id: "12" },
];

export const usernames = [
  { key: "Куликов Иван Владимирович", id: "1" },
  { key: "Куликов Иван Владимирович", id: "2" },
  { key: "Куликов Иван Владимирович", id: "3" },
];
export const roles = [{ key: "Базовая", id: "1" }];
