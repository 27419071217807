<template>
  <component :is="layout">
    <notifications position="bottom left" classes="notification" width="340" />
    <transition name="fade" mode="out-in">
      <router-view v-slot="{ Component }">
        <transition>
          <Loading v-if="loading" style="height: 100vh" />
          <component :is="Component" v-else />
        </transition>
      </router-view>
    </transition>
  </component>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import Loading from "@/components/Loading";
import AuthLayout from "@/Layouts/AuthLayout";
import DefaultLayout from "@/Layouts/DefaultLayout";
import { AuthAPI } from "@/api/AuthAPI";
import { useAuthModule } from "@/store";

export default {
  name: "App",
  components: { DefaultLayout, AuthLayout, Loading },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const layout = computed(() => route.meta.layout || "DefaultLayout");
    const store = useAuthModule();
    const isLoggenIn = computed(() => store.logginIn);
    const loading = ref(false);

    onMounted(() => {
      if (isLoggenIn.value) {
        // loading.value = true;
        loading.value = false;
        AuthAPI.getProfile().then((res) => {
          store.setUser(res.data);
          loading.value = false;
        });
      } else {
        router.push("/signin");
      }
    });

    return { layout, loading };
  },
  watch: {
    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      this.transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
    },
  },
};
</script>

<style lang="scss">
.vue-notification-template.notification {
  box-shadow: 0px 24px 32px rgb(19 23 35 / 3%), 0px 16px 24px rgb(19 23 35 / 2%),
    0px 4px 8px rgb(19 23 35 / 2%), 0px 0px 1px rgb(19 23 35 / 4%);
  border-radius: 8px;
  background: #3e445b;
  padding: 16px;
  background-repeat: no-repeat;
  background-position: center left 16px;
  margin-bottom: 10px;
  margin-left: 10px;
  &.success {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAyMCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOS4wMzAzIDAuNzE5NjdDMTkuMzIzMiAxLjAxMjU2IDE5LjMyMzIgMS40ODc0NCAxOS4wMzAzIDEuNzgwMzNMNy41MzAzMyAxMy4yODAzQzcuMzg3NjQgMTMuNDIzIDcuMTkzNDYgMTMuNTAyMiA2Ljk5MTY3IDEzLjVDNi43ODk4OCAxMy40OTc3IDYuNTk3NTEgMTMuNDE0MiA2LjQ1ODAyIDEzLjI2ODRMMC45NTgwMiA3LjUxODQyQzAuNjcxNzA2IDcuMjE5MDkgMC42ODIyNTYgNi43NDQzMyAwLjk4MTU4NCA2LjQ1ODAyQzEuMjgwOTEgNi4xNzE3IDEuNzU1NjcgNi4xODIyNSAyLjA0MTk4IDYuNDgxNThMNy4wMTE5MiAxMS42Nzc0TDE3Ljk2OTcgMC43MTk2N0MxOC4yNjI2IDAuNDI2Nzc3IDE4LjczNzQgMC40MjY3NzcgMTkuMDMwMyAwLjcxOTY3WiIgZmlsbD0iIzVCQzREMSIvPgo8L3N2Zz4K");
  }
  &.error {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE5IDE5TDUgNSIgc3Ryb2tlPSJyZWQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTkgNUw1IDE5IiBzdHJva2U9InJlZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
  }
  &.warn {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzQ2XzE0MDcxKSI+CjxwYXRoIGQ9Ik0xMiAxMi4zMzM0VjE2TTMgMTJDMyA5LjYxMzA1IDMuOTQ4MjEgNy4zMjM4NyA1LjYzNjA0IDUuNjM2MDRDNy4zMjM4NyAzLjk0ODIxIDkuNjEzMDUgMyAxMiAzQzE0LjM4NjkgMyAxNi42NzYxIDMuOTQ4MjEgMTguMzY0IDUuNjM2MDRDMjAuMDUxOCA3LjMyMzg3IDIxIDkuNjEzMDUgMjEgMTJDMjEgMTQuMzg2OSAyMC4wNTE4IDE2LjY3NjEgMTguMzY0IDE4LjM2NEMxNi42NzYxIDIwLjA1MTggMTQuMzg2OSAyMSAxMiAyMUM5LjYxMzA1IDIxIDcuMzIzODcgMjAuMDUxOCA1LjYzNjA0IDE4LjM2NEMzLjk0ODIxIDE2LjY3NjEgMyAxNC4zODY5IDMgMTJaIiBzdHJva2U9Im9yYW5nZSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxyZWN0IHg9IjExLjI1IiB5PSI4LjUxNzY0IiB3aWR0aD0iMS41IiBoZWlnaHQ9IjEuNSIgcng9IjAuNzUiIGZpbGw9Im9yYW5nZSIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzQ2XzE0MDcxIj4KPHJlY3Qgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=");
  }
  &-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
  }
}
.notification-content {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding-left: 35px;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
