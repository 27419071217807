<template>
  <div class="form-container with-steps">
    <Steps :steps="3" :activeStep="getLastChar(activeStep)" />
    <RolesSettings :form="form" v-if="activeStep === 'step1'" />

    <RolesSources :form="form" v-if="activeStep === 'step2'" />

    <RolesEmployees :form="form" v-if="activeStep === 'step3'" />

    <ProgressBar
      :count="progress.length"
      :total="totalRequired"
      :loading="loading"
      :actionText="actionText"
      :cancelText="cancelText"
      @add="nextStep"
      @cancel="prevStep"
    />
  </div>
</template>

<script setup>
import { notify } from "@kyvg/vue3-notification";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import { Form } from "./Forms/Form";
import progressCounters from "@/helpers/progressCounters";
import ProgressBar from "@/components/ProgressBar";
import RolesSettings from "./Forms/RolesSettings.vue";
import RolesSources from "./Forms/RolesSources.vue";
import RolesEmployees from "./Forms/RolesEmployees.vue";
import Steps from "./components/Steps.vue";

const { progressSteps, totalRequiredSteps } = progressCounters;
const router = useRouter();
const form = Form();
const loading = ref(false);

const cancelText = ref("Отмена");
const actionText = ref("Далее");
const activeStep = ref("step1");

const getLastChar = (str) => str.charAt(str.length - 1);

const progress = computed(() => progressSteps(activeStep.value, form));
const totalRequired = computed(() =>
  totalRequiredSteps(activeStep.value, form)
);

const nextStep = () => {
  if (progress.value.length >= totalRequired.value) {
    if (activeStep.value === "step3") {
      add();
    } else if (activeStep.value === "step2") {
      activeStep.value = "step3";
    } else if (activeStep.value === "step1") {
      activeStep.value = "step2";
      cancelText.value = "Назад";
    }
  } else {
    notify({
      type: "warn",
      text: `Не заполнено ${
        totalRequired.value - progress.value.length
      } пунктов`,
    });
  }
  loading.value = false;
};

const prevStep = () => {
  if (activeStep.value === "step1") {
    cancel();
  } else if (activeStep.value === "step2") {
    activeStep.value = "step1";
    actionText.value = "Далее";
    cancelText.value = "Отмена";
  } else if (activeStep.value === "step3") {
    activeStep.value = "step2";
    actionText.value = "Добавить";
  }
};

const add = async () => {
  loading.value = true;
  const payload = {};
  for (const item in form) {
    payload[item] = form[item].value;
  }
  if (payload.all_area && payload.regions.length) {
    payload.regions = [];
  }
  if (
    progress.value.length >= totalRequired.value &&
    activeStep.value === "step3"
  ) {
    notify({
      type: "success",
      text: "Сервис успешно добавлен",
    });
    // Uncomment and replace with actual API call
    // await ServicesAPI.add({ ...payload })
    //   .then(() => {
    //     notify({
    //       type: "success",
    //       text: "Сервис успешно добавлен",
    //     });
    //     router.push("/services");
    //   })
    //   .catch((err) => {
    //     notifications(err);
    //   });
  }
  loading.value = false;
};

const cancel = () => {
  router.go(-1);
};
</script>
