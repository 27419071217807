<template>
  <div class="form">
    <div class="form-group">
      <div class="item">
        <FormInput
          v-model="localForm.surname.value"
          :validation="localForm.surname"
          type="text"
          :label="translation ? 'Фамилия (англ.)' : 'Фамилия'"
          size="small"
          placeholder="Введите фамилию"
        />
        <span
          v-if="localForm.surname.touched && localForm.surname.errors.required"
          class="field-group__error"
          >Поле 'Фамилия' обязательно к заполнению!</span
        >
      </div>
      <div class="item">
        <FormInput
          v-model="localForm.name.value"
          :validation="localForm.name"
          type="text"
          :label="translation ? 'Имя (англ.)' : 'Имя'"
          size="small"
          placeholder="Введите Имя"
        />
        <span
          v-if="localForm.name.touched && localForm.name.errors.required"
          class="field-group__error"
          >Поле 'Имя' обязательно к заполнению!</span
        >
      </div>
    </div>
    <div class="form-group-full">
      <div class="item">
        <FormInput
          v-model="localForm.patronymic.value"
          :validation="localForm.patronymic"
          type="text"
          :label="translation ? 'Отчество (англ.)' : 'Отчество'"
          size="small"
          placeholder="Введите Отчество"
        />
        <span
          v-if="
            localForm.patronymic.touched && localForm.patronymic.errors.required
          "
          class="field-group__error"
          >Поле 'Отчество' обязательно к заполнению!</span
        >
      </div>
    </div>

    <div class="form-group">
      <div class="item">
        <FormInput
          v-model="localForm.email.value"
          :validation="localForm.email"
          type="text"
          label="Email"
          size="small"
          placeholder="Введите Email"
        />
        <span
          v-if="localForm.email.touched && localForm.email.errors.required"
          class="field-group__error"
          >Поле 'Email' обязательно к заполнению!</span
        >
      </div>
      <div class="item">
        <FormInput
          v-model="localForm.birthday.value"
          :validation="localForm.birthday"
          type="text"
          :label="translation ? 'Дата рождения (англ.)' : 'Дата рождения'"
          size="small"
          placeholder="Введите Дату рождения"
        />
        <span
          v-if="
            localForm.birthday.touched && localForm.birthday.errors.required
          "
          class="field-group__error"
          >Поле 'Дата рождения' обязательно к заполнению!</span
        >
      </div>
    </div>

    <div class="item">
      <FormSelect
        v-model="localForm.departmentId.value"
        :validation="localForm.departmentId"
        :items="departments"
        :label="translation ? 'Подразделение (англ.)' : 'Подразделение'"
        placeholder="Выберите подразделение"
      />
      <span
        v-if="
          localForm.departmentId.touched &&
          localForm.departmentId.errors.required
        "
        class="field-group__error"
        >Выберите подразделение!</span
      >
    </div>

    <div class="item">
      <FormSelect
        v-model="localForm.positionId.value"
        :validation="localForm.positionId"
        :items="positions"
        :label="translation ? 'Должность (англ.)' : 'Должность'"
        placeholder="Выберите должность"
      />
      <span
        v-if="
          localForm.positionId.touched && localForm.positionId.errors.required
        "
        class="field-group__error"
        >Выберите должность!</span
      >
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { DepartmentsAPI } from "@/api/DepartmentsAPI";
import { PositionsAPI } from "@/api/PositionsAPI";

const props = defineProps({
  form: {
    type: Object,
    default: () => {},
  },
  translation: {
    type: Boolean,
    default: false,
  },
});

const localForm = ref(props.form);

const departments = ref([]);
const positions = ref([]);

onMounted(() => {
  DepartmentsAPI.getAll().then((res) => {
    departments.value = res.data;
  });
  PositionsAPI.getAll().then((res) => {
    positions.value = res.data;
  });
});
</script>
