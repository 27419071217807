<template>
  <div class="form-container">
    <div class="top-line">
    <h3 class="title">Общая информация</h3>
    <Dropdown>
        <template #trigger>
          <Button
            text
            size="small"
            icon="pi pi-ellipsis-h"
            severity="secondary"
          />
        </template>
        <template #content>
          <ul>
            <li @click="edit" v-if="checkEditPermission('providers')">
              Редактировать
            </li>
            <li @click="deleteUser" v-if="checkPermission('providers')">
              Удалить
            </li>
          </ul>
        </template>
      </Dropdown>
    </div>  
    <div class="divider"></div>
    <div class="form-row">
    <div class="item">
      <FormInput
        v-model="localForm.name.value"
        :validation="localForm.name"
        type="text"
        label="Наименование учетной записи"
        size="small"
        placeholder="Введите наименование учетной записи"
        :disabled="!isEditable"
      />
      <span
        v-if="localForm.name.touched && localForm.name.errors.required"
        class="field-group__error"
        >Поле 'Наименование учетной записи' обязательно к заполнению!</span
      >
    </div>

    <div class="item">
      <FormSelect
        v-model="localForm.service.value"
        :validation="localForm.service"
        :items="services"
        label="Сервис"
        placeholder="Выберите сервис"
        :disabled="!isEditable"
      />
      <span
        v-if="localForm.service.touched && localForm.service.errors.required"
        class="field-group__error"
      >
        Выберите тип сервиса
      </span>
    </div>
  </div>
  <div class="form-row">
    <div class="item">
      <FormSelect
        v-model="localForm.update_period.value"
        :validation="localForm.update_period"
        :items="updatePeriods"
        label="Укажите период обновления"
        placeholder="Укажите период обновления"
        :disabled="!isEditable"
      />
      <span
        v-if="
          localForm.update_period.touched &&
          localForm.update_period.errors.required
        "
        class="field-group__error"
        >Укажите период обновления</span
      >
    </div>

    <div class="item">
      <FormSelect
        v-model="localForm.username.value"
        :validation="localForm.username"
        :items="usernames"
        label="ФИО Пользователя"
        placeholder="Укажите ФИО Пользователя"
        :disabled="!isEditable"
      />
      <span
        v-if="localForm.username.touched && localForm.username.errors.required"
        class="field-group__error"
        >Выберите ФИО Пользователя</span
      >
    </div>
    </div>

    <div class="deactivation">
          <div class="deactivation__title">
 
            Заблокировать учетную запись
          </div>
          <div class="deactivation-col">
            <p>
              Учетная запись будет заблокирована. <br>
              доступ к платформе будет полностью ограничен
            </p>
            <Button
              type="button"
              @click="deactivate"
              :disabled=false
            >
              {{
                 "Заблокировать"
 
              }}
            </Button>
          </div>
        </div>


    <h3 class="title">Роль учетной записи</h3>
    <div class="divider"></div>

    <div class="item">
      <FormSelect
        v-model="localForm.role.value"
        :validation="localForm.role"
        :items="roles"
        label="Роль"
        placeholder="Укажите роль"
        :disabled="!isEditable"
      />
      <span
        v-if="localForm.role.touched && localForm.role.errors.required"
        class="field-group__error"
        >Выберите роль</span
      >
    </div>

    <h3 class="title">Права в сервисе</h3>
    <div class="divider"></div>

    <div class="item">
      <FormInput
        v-model="localForm.permissions.value"
        :validation="localForm.permissions"
        type="text"
        label="Права"
        size="small"
        placeholder="Введите права"
        :disabled="!isEditable"
      />
      <span
        v-if="
          localForm.permissions.touched && localForm.permissions.errors.required
        "
        class="field-group__error"
        >Поле 'Права' обязательно к заполнению!</span
      >
    </div>

    <h3 v-if="localForm.password.value" class="title">Пароль учетной записи</h3>

    <h3 v-else class="title">Создайте пароль для учетной записи</h3>

    <div class="item item--short" v-if="localForm.password.value">
      <FormInput
        v-model="localForm.password.value"
        type="password"
        label="Пароль"
        :validation="{}"
        :disabled="true"
      />
    </div>

    <div class="item item--short">
      <button
        class="btn"
        type="button"
        @click="showModal = true"
        v-if="localForm.password.value"
      >
        Изменить пароль для учетной записи
      </button>
      <button class="btn" type="button" @click="showModal = true" v-else>
        Создать пароль для учетной записи
      </button>
    </div>

    <Modalka
      v-if="showModal"
      :loading="false"
      @close="closeModal"
      @add="changePassword"
      actionText="Изменить"
      :headerText="`Изменить пароль`"
    >
      <template v-slot:body>
        <form @submit.prevent="login">
          <div class="password-block">
            <FormInput
              v-model="localForm.password.value"
              :validation="localForm.password"
              type="password"
              label="Пароль"
              class="password-block__input"
            />
            <FormButton
              class="password-block__button"
              label="Сгенерировать"
              type="button"
              @submit="handleGeneratePassword(8)"
            />
            <p class="password-block__text">
              Доступно внести пароль вручную или сгенерировать автоматически
            </p>
            <span
              v-if="
                localForm.password.touched && localForm.password.errors.required
              "
              class="field-group__error"
              >Поле 'Пароль' обязательно к заполнению!</span
            >
          </div>
          <FormInput
            v-model="localForm.confirm_password.value"
            :validation="localForm.confirm_password"
            type="password"
            label="Пароль"
          />
          <span
            v-if="
              localForm.confirm_password.touched &&
              localForm.confirm_password.errors.required
            "
            class="field-group__error"
            >Поле 'Подтвердить пароль' обязательно к заполнению!</span
          >
        </form>
      </template>
    </Modalka>
  </div>
</template>

<script setup>
import { ref } from "vue";
import Modalka from "@/components/modals/Modalka";
import generatePassword from "@/helpers/generatePassword";
import { checkEditPermission, checkPermission } from "@/composables/actions";
import { services, updatePeriods, usernames } from "@/enums/enum-accounts";
import Dropdown from "@/components/Dropdown.vue";
import Button from "primevue/button";


const props = defineProps({
  form: {
    type: Object,
    default: () => {},
  },
  isEditable: {
    type: Boolean,
    default: ref(false),
  }
});

const localForm = ref(props.form);
const showModal = ref(false);
const isEditable = ref(props.isEditable);

const changePassword = () => {
  showModal.value = false;
};

const closeModal = () => {
  localForm.value.password.value = "";
  localForm.value.confirm_password.value = "";
  showModal.value = false;
};

const handleGeneratePassword = (length) => {
  const password = generatePassword(length);
  localForm.value.password = password;
  localForm.value.confirm_password = password;
};

const edit = () => {
  isEditable.value = true;
};

</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars";

.btn {
  background: #5bc4d1;
  color: #fff;
  letter-spacing: 0.3px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 9px;
  width: 100%;
  height: 48px;
  transition: 0.3s;
}

.form-row {
  display: flex;
  gap: 20px; /* Расстояние между элементами */
}

.item {
  flex: 1; /* Элементы будут растягиваться, чтобы занимать равное место */
}

.password-block {
  display: flex;
  align-items: flex-end;
  column-gap: 24px;
  flex-wrap: wrap;
  .password-block__input {
    flex-basis: 65%;
    margin-bottom: 0;
  }
  .password-block__button {
    flex-basis: 30%;
  }
  .password-block__text {
    flex-basis: 100%;
    margin-top: 8px;
    margin-bottom: 24px;
    font-size: 14px;
    color: $theme500;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #dee2e6;
  margin: 20px 0;
}

.deactivation {
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 24px;
  background: #F1FDFF;
  &__title {
    color: #3e445b;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 20px;
  }
  &-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      line-height: 20px;
    }
    button {
      background: #F95266;
      border-radius: 48px;
      border: 0px;
      height: 48px;
      color: #FFF;
      padding: 0 30px;
      font-size: 16px;
      letter-spacing: 0.3px;
      &.warning {
        background: #F95266;
        color: #FFF;
      }
      &:disabled {
        background: #3E445B;
        color: #FFF;
        opacity: .8;
      }
    }
  }
}

</style>
