import ValidationsForm from "@/helpers/validations";
import { useForm } from "@/composables/form";

const { required } = ValidationsForm;

export function FormData() {
  return useForm({
    name: {
      value: "",
      validators: { required },
    },
    service: {
      value: "",
      validators: { required },
    },
    update_period: {
      value: "",
      validators: { required },
    },
    username: {
      value: "",
      validators: { required },
    },
    role: {
      value: "",
      validators: { required },
    },
    permissions: {
      value: "",
      validators: { required },
    },
    password: {
      value: "",
      validators: { required },
    },
    confirm_password: {
      value: "",
      validators: { required },
    },
  }).form;
}
