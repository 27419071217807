<template>
  <div class="form-container">
    <div class="top-line">
      <Tabs :tabs="tabs" />
      <div class="add-button" v-if="route.name === 'roles-employees'">
        <button type="button" @click="isShowEmployeeModal = true">
          <svg
            width="16px"
            role="img"
            height="16px"
            xmlns="http://www.w3.org/2000/svg"
            class="v-icon"
          >
            <use href="#plus" />
          </svg>
        </button>
      </div>
      <div v-else>
        <Dropdown>
          <template #trigger>
            <Button
              text
              size="small"
              icon="pi pi-ellipsis-h"
              severity="secondary"
            />
          </template>
          <template #content>
            <ul>
              <li @click="edit">Редактировать</li>
              <li @click="isShowDeleteModal = true">Удалить</li>
            </ul>
          </template>
        </Dropdown>
      </div>
    </div>

    <router-view v-slot="{ Component }" v-if="!loading">
      <transition>
        <component :is="Component" :service="service" />
      </transition>
    </router-view>

    <AddEmployeeModal
      :isShowModal="isShowEmployeeModal"
      :closeModal="closeModal"
    />
    <DeleteRole :isShowModal="isShowDeleteModal" :closeModal="closeModal" />
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import Tabs from "@/components/Tabs.vue";
import Dropdown from "@/components/Dropdown.vue";
import Button from "primevue/button";
import AddEmployeeModal from "./Forms/AddEmployeeModal.vue";
import DeleteRole from "./components/DeleteRole.vue";

const route = useRoute();
const router = useRouter();
const service = ref(null);
const loading = ref(true);

const isShowEmployeeModal = ref(false);
const isShowDeleteModal = ref(false);

onMounted(() => {
  service.value = {
    description: "Роль расширяет полномочия пользователя до администратора ИБ",
    canRequestPermission: true,
    owner: "Куликов Иван Владимирович",
    subdivision: "Техническая дирекция",
    role: {
      name: "Техническая дирекция",
      job: "Руководитель отдела",
    },
  };
  loading.value = false;
});

const tabs = ref([
  { title: "Общие настройки", route: "settings" },
  { title: "Источник", route: "source" },
  { title: "Пользователи", route: "roles-employees" },
]);

const closeModal = () => {
  isShowEmployeeModal.value = false;
  isShowDeleteModal.value = false;
};

const edit = () => {
  router.push(
    `/roles/edit/${route.params.id}/settings?name=${route.query.name}`
  );
};
</script>
<style lang="scss" scoped>
.add-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;

  button {
    background: #5bc4d1;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
