import { AuthAPI } from "@/api/AuthAPI";
import { createPinia } from "pinia";
import { DefaultAPIInstance } from "@/api";
import { notify } from "@kyvg/vue3-notification";
import { useAuthModule } from "@/store";
import notifications from "@/helpers/notifications";

import router from "@/router";
const pinia = createPinia();
const authStore = useAuthModule(pinia);
// const userPermissions = authStore.getUserPermissions;

// TO DO: rename to checkDeletePermission
// export function checkPermission(name) {
//   return (
//     userPermissions?.find((item) => item.key === `${name}:delete`) ?? false
//   );
// }
export function checkPermission() {
  return true;
}

// export function checkEditPermission(name) {
//     return (
//         userPermissions?.find((item) => item.key === `${name}:edit`) ?? false
//     );
// }
export function checkEditPermission() {
  return true;
}

export function remove(items, id, name, api) {
  // const hasPermission =
  //     userPermissions?.find((item) => item.key === `${name}:delete`) ?? false;
  const hasPermission = true;
  if (hasPermission) {
    return DefaultAPIInstance.delete(`${api}/${id}`)
      .then(() => {
        const index = items.findIndex((n) => n.id === id);
        if (index !== -1) {
          items.splice(index, 1);
          notify({
            type: "success",
            text: "Удалено",
          });
        }
      })
      .catch((err) => {
        notifications(err);
      });
  }

  return notify({
    type: "error",
    text: "У Вас нет прав на удаление",
  });
}

export async function auth(id) {
  const res = await AuthAPI.authUserById(id);
  authStore.setToken(res);

  const profile = await AuthAPI.getProfile();
  authStore.setUser(profile.data);

  await router.push("/employees");
  router.go(0);
}
