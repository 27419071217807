<template>
  <Form :form="form" />
  <!-- <div class="form-container">
    <div class="top-line">
      <h3 class="title">Общая информация</h3>
      <Dropdown>
        <template #trigger>
          <Button
            text
            size="small"
            icon="pi pi-ellipsis-h"
            severity="secondary"
          />
        </template>
        <template #content>
          <ul>
            <li @click="edit" v-if="checkEditPermission('providers')">
              Редактировать
            </li>
            <li @click="deleteUser" v-if="checkPermission('providers')">
              Удалить
            </li>
          </ul>
        </template>
      </Dropdown>
    </div>

    <div class="item">
      <div class="label">Сервис</div>
      <div class="text">{{ account?.service }}</div>
    </div>
    <div class="item">
      <div class="label">Дата последнего обновления</div>
      <div class="text">{{ account?.date }}</div>
    </div>
    <div class="item">
      <div class="label">Пользователь</div>
      <div class="text">{{ account?.user }}</div>
    </div>
    <div class="item item--short">
      <Checkbox :text="'Активировать учетную запись'" />
    </div>

    <h3 class="title">Назначенные роли учетной записи</h3>

    <div class="item">
      <div class="label">Роль</div>
      <div class="text">{{ account?.role }}</div>
    </div>

    <h3 class="title">Права в сервисе</h3>

    <div class="item">
      <div class="label">Права</div>
      <div class="text">{{ account?.permissions }}</div>
    </div>

    <h3 class="title">Пароль учетной записи</h3>

    <div class="item item--short">
      <FormInput
        v-model="account.password"
        type="password"
        label="Пароль"
        :validation="{}"
        :disabled="true"
      />
    </div>
    <div class="item item--short">
      <button class="btn" type="button" @click="showModal = true">
        Изменить пароль для учетной записи
      </button>
    </div>
  </div>
  <Modalka
    v-if="showModal"
    :loading="false"
    @close="closeModal"
    @add="changePassword"
    actionText="Изменить"
    :headerText="`Изменить пароль`"
  >
    <template v-slot:body>
      <form @submit.prevent="login">
        <div class="password-block">
          <FormInput
            v-model="form.password.value"
            :validation="form.password"
            type="password"
            label="Пароль"
            class="password-block__input"
          />
          <FormButton
            class="password-block__button"
            label="Сгенерировать"
            type="button"
            @submit="handleGeneratePassword(8)"
          />
          <p class="password-block__text">
            Доступно внести пароль вручную или сгенерировать автоматически
          </p>
          <span
            v-if="form.password.touched && form.password.errors.required"
            class="field-group__error"
            >Поле 'Пароль' обязательно к заполнению!</span
          >
        </div>
        <FormInput
          v-model="form.confirm_password.value"
          :validation="form.confirm_password"
          type="password"
          label="Пароль"
        />
        <span
          v-if="form.password.touched && form.password.errors.required"
          class="field-group__error"
          >Поле 'Подтвердить пароль' обязательно к заполнению!</span
        >
      </form>
    </template>
  </Modalka> -->
</template>

<script setup>
// import { ref } from "vue";
// import { useRoute, useRouter } from "vue-router";
// // import Modalka from "@/components/modals/Modalka";
// // import Dropdown from "@/components/Dropdown.vue";
// // import Button from "primevue/button";
// // import { checkEditPermission, checkPermission } from "@/composables/actions";
// // import Checkbox from "@/components/Checkbox";
// import { account } from "@/enums/enum-accounts";
// import generatePassword from "@/helpers/generatePassword";
import Form from "./Forms/Form.vue";
import { FormData } from "./Forms/Form";


// const showModal = ref(false);

const form = FormData();

// const changePassword = () => {};

// const route = useRoute();
// const router = useRouter();

// const closeModal = () => {
//   form.password.value = "";
//   form.confirm_password.value = "";
//   showModal.value = false;
// };

// const handleGeneratePassword = (length) => {
//   const password = generatePassword(length);
//   form.password.value = password;
//   form.confirm_password.value = password;
// };

// const edit = () => {
//   router.push(`/accounts/edit/${route.params.id}?name=${account.service}`);
// };
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars";
.btn {
  background: #5bc4d1;
  color: #fff;
  letter-spacing: 0.3px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 9px;
  width: 100%;
  height: 48px;
  transition: 0.3s;
}

.form-container{
  background: #FFFFFF;
  max-width: 804px;
  box-shadow: 0px 3px 6px 0px rgba(19, 23, 35, 0.04), 0px 0px 2px 0px rgba(19, 23, 35, 0.06), 0px 0px 1px 0px rgba(19, 23, 35, 0.04);
  padding: 32px;
    }



.password-block {
  display: flex;
  align-items: flex-end;
  column-gap: 24px;
  flex-wrap: wrap;
  .password-block__input {
    flex-basis: 65%;
    margin-bottom: 0;
  }
  .password-block__button {
    flex-basis: 30%;
  }
  .password-block__text {
    flex-basis: 100%;
    margin-top: 8px;
    margin-bottom: 24px;
    font-size: 14px;
    color: $theme500;
  }
}
</style>
