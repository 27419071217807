import { useAuthModule } from "@/store";

export default function auth({ next }) {
  const store = useAuthModule();
  if (!store.logginIn) {
    return next({
      name: "login",
    });
  }
  return next();
}
