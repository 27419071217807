import { reactive, computed } from "vue";
import { useField } from "@/composables/field";

export function useForm(init = {}, preset = {}) {
  const form = reactive({});
  const validKey = "valid";

  for (const [key, value] of Object.entries(init)) {
    value.value = preset[key] || value.value;
    form[key] = useField(value);
  }

  const withoutValid = (k) => k !== validKey;

  form[validKey] = computed(() => {
    return Object.keys(form)
      .filter(withoutValid)
      .reduce((acc, key) => {
        acc = form[key].valid;
        return acc;
      }, true);
  });

  const values = computed(() =>
    Object.entries(form).reduce(
      (obj, [name, field]) => ({
        ...obj,
        [name]: field.value,
      }),
      {}
    )
  );

  return { form, values };
}
