import { DefaultAPIInstance } from "@/api";

export const PositionsAPI = {
  add(payload) {
    return DefaultAPIInstance.post("/positions", payload);
  },

  update(payload, id) {
    return DefaultAPIInstance.patch(`/positions/${id}`, payload);
  },

  getAll() {
    return DefaultAPIInstance.get("/positions");
  },

  getById(id) {
    return DefaultAPIInstance.get(`/positions/${id}`);
  },

  delete(id) {
    return DefaultAPIInstance.delete(`/positions/${id}`);
  },
};
