<template>
  <div v-if="localForm" class="w-100">
    <h3 class="title">Общая информация</h3>
    <div class="item">
      <FormInput
        v-model="localForm.role_name.value"
        :validation="localForm.role_name"
        type="text"
        size="small"
        label="Отображаемое название роли"
        placeholder="Введите отображаемое название роли"
      />
      <span
        v-if="
          localForm.role_name.touched && localForm.role_name.errors.required
        "
        class="field-group__error"
        >Поле 'Отображаемое название роли' обязательно к заполнению!</span
      >
    </div>
    <div class="item">
      <FormInput
        v-model="localForm.role_description.value"
        :validation="localForm.role_description"
        type="text"
        label="Описание роли"
        placeholder="Описание роли"
      />
      <span
        v-if="
          localForm.role_description.touched &&
          localForm.role_description.errors.required
        "
        class="field-group__error"
        >Поле 'Описание роли' обязательно к заполнению!</span
      >
    </div>

    <div class="item">
      <label class="checkbox-block">
        <span> Можно запрашивать разрешение </span>
        <Checkbox v-model="localForm.canAccessPermissions" :binary="true" />
      </label>
    </div>

    <div class="item item--grid">
      <div>
        <FormSelect
          v-model="localForm.role_owner.value"
          :validation="localForm.role_owner"
          :items="roleOwners"
          label="Владелец роли"
          placeholder="Введите владелец роли"
        />
        <span
          v-if="
            localForm.role_owner.touched && localForm.role_owner.errors.required
          "
          class="field-group__error"
          >Поле 'Владелец роли' обязательно к заполнению!</span
        >
      </div>
      <div>
        <FormSelect
          v-model="localForm.role_owner_subdivision.value"
          :validation="localForm.role_owner_subdivision"
          :items="subdivisions"
          label="Подразделение"
          placeholder="Выберите подразделение"
        />
        <span
          v-if="
            localForm.role_owner_subdivision.touched &&
            localForm.role_owner_subdivision.errors.required
          "
          class="field-group__error"
          >Поле 'Подразделение' обязательно к заполнению!</span
        >
      </div>
    </div>

    <h3 class="title">Автоматически присвоить роль</h3>

    <div
      class="item item--box"
      v-if="route.name === 'edit-settings' || route.name === 'roleView'"
    >
      <div>
        <div class="subtitle">
          {{ localForm.role_subdivision.value }}
        </div>
        <div class="text">{{ localForm.role_job.value }}</div>
      </div>
      <button type="button" @click="isShowModal = true">
        <svg
          width="16px"
          role="img"
          height="16px"
          xmlns="http://www.w3.org/2000/svg"
          class="v-icon"
        >
          <use href="#trash-2" />
        </svg>
      </button>
    </div>

    <div class="item item--grid" v-else>
      <div>
        <FormSelect
          v-model="localForm.role_subdivision.value"
          :validation="localForm.role_subdivision"
          :items="subdivisions"
          label="Подразделение"
          placeholder="Выберите подразделение"
        />
        <span
          v-if="
            localForm.role_subdivision.touched &&
            localForm.role_subdivision.errors.required
          "
          class="field-group__error"
          >Поле 'Подразделение' обязательно к заполнению!</span
        >
      </div>
      <div>
        <FormSelect
          v-model="localForm.role_job.value"
          :validation="localForm.role_job"
          :items="jobs"
          label="Должность"
          placeholder="Выберите должность"
        />
        <span
          v-if="
            localForm.role_job.touched && localForm.role_job.errors.required
          "
          class="field-group__error"
          >Поле 'Должность' обязательно к заполнению!</span
        >
      </div>
    </div>

    <div class="item--short">
      <FormButton
        label="Добавить подразделение"
        type="button"
        @submit="isShowModal = true"
      />
    </div>
  </div>

  <Modalka
    v-if="isShowModal"
    :loading="false"
    @close="isShowModal = false"
    @add="addSubdivision"
    actionText="Добавить"
    :headerText="`Добавить подразделение`"
  >
    <template v-slot:body>
      <form class="modal-form">
        <div class="password-block">
          <FormSelect
            v-model="localForm.role_subdivision.value"
            :validation="localForm.role_subdivision"
            :items="subdivisions"
            label="Подразделение"
            placeholder="Выберите подразделение"
          />
          <FormSelect
            v-model="localForm.role_job.value"
            :validation="localForm.role_job"
            :items="jobs"
            label="Должность"
            placeholder="Выберите должность"
          />
        </div>
      </form>
    </template>
  </Modalka>
</template>

<script setup>
import { ref } from "vue";
import Checkbox from "primevue/checkbox";
import Modalka from "@/components/modals/Modalka";
import { useRoute } from "vue-router";

const route = useRoute();

const roleOwners = [
  { key: "Куликов Иван Владимирович", id: "1" },
  { key: "Перелеска Ирина Витальевна", id: "2" },
];
const subdivisions = ref([
  { id: "1", name: "Отдел Информационных Технологий" },
  { id: "2", name: "Бухгалтерия" },
  { id: "3", name: "Управление проектами" },
  { id: "4", name: "Техническая дирекция" },
  { id: "5", name: "Отдел качества" },
  { id: "6", name: "Экономическая безопасность" },
]);
const jobs = ref([
  { id: "1", name: "Администратор" },
  { id: "2", name: "Разработчик" },
  { id: "3", name: "Главный бухгалтер" },
  { id: "4", name: "Бухгалтер" },
  { id: "5", name: "Руководитель отдела" },
  { id: "6", name: "Инженер" },
]);

const props = defineProps({
  form: {
    type: Object,
    default: () => {},
  },
});

const localForm = ref(props.form);

const isShowModal = ref(false);

const addSubdivision = () => {};
</script>

<style scoped lang="scss">
button {
  background: #fff;
  border-radius: 50%;
  border: 1px solid rgba(#575e76, 0.14);
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-form {
  width: 100%;
}
</style>
