<template>
  <div>
    <Form :form="form" />

    <ProgressBar
      :count="progress.length"
      :total="totalRequired"
      :loading="loading"
      actionText="Сохранить"
      @add="add"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { FormData } from "./Forms/Form";
import progressCounters from "@/helpers/progressCounters";
import ProgressBar from "@/components/ProgressBar";
import Form from "./Forms/Form.vue";

const { progressCounter, totalRequiredCounter } = progressCounters;
const form = FormData();
const loading = ref(false);

const progress = computed(() => progressCounter(form));
const totalRequired = computed(() => totalRequiredCounter(form));

onMounted(() => {
  form.name.value = "Iscra_24_Mob";
  form.service.value = "МОБ СОШ “Искра”";
  form.update_period.value = "3 месяца";
  form.username.value = "Куликов Иван Владимирович";
  form.role.value = "Базовая";
  form.permissions.value =
    "CN+Domain Employees, CN=Employees, DC=demo, DC=stand";
  form.password.value = "password";
  form.confirm_password.value = "password";
});
</script>
