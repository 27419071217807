class Status {
  constructor(type, msg, value) {
    this.type = type;
    this.msg = msg;
    this.value = value;
  }

  get message() {
    return this.msg;
  }

  set message(newMsg) {
    this.msg = newMsg;
  }
}

const STATUS_TYPES = {
  success: "success",
  error: "error",
  warning: "warning",
};

export const ADMINISTRATORS_STATUSES = {
  active: new Status(STATUS_TYPES.success, "Активный", "active"),
  inactive: new Status(STATUS_TYPES.warning, "Не активный", "inactive"),
};

export const USER_STATUSES = {
  active: new Status(STATUS_TYPES.success, "Активный", "active"),
  inactive: new Status(STATUS_TYPES.warning, "Заблокирован", "inactive"),
};

export const EMAIL_CONFIRM_STATUSES = {
  confirmed: new Status(STATUS_TYPES.success, "Подтвержден", "confirmed"),
  unconfirmed: new Status(STATUS_TYPES.error, "Не подтвержден", "unconfirmed"),
};

export const PROVIDER_STATUSES = {
  inactive: new Status(STATUS_TYPES.warning, "Не активный", "inactive"),
  active: new Status(STATUS_TYPES.success, "Активный", "active"),
  suspended: new Status(STATUS_TYPES.error, "Приостановлен", "suspended"),
};

export const SERVICE_TYPE_STATUSES = {
  inactive: new Status(STATUS_TYPES.warning, "Приостановлен", "inactive"),
  active: new Status(STATUS_TYPES.success, "Активный", "active"),
};

export const PROVIDER_AUTH_STATUSES = {
  trusted: new Status(STATUS_TYPES.success, "Доверенный", "trusted"),
  untrusted: new Status(STATUS_TYPES.error, "Не доверенный", "untrusted"),
};

export const SERVICE_TEST_STATUSES = {
  successfully: new Status(STATUS_TYPES.success, "Успешно", "successfully"),
  failure: new Status(STATUS_TYPES.error, "Ошибка", "failure"),
  untested: new Status(STATUS_TYPES.warning, "Тест не проводился", "untested"),
};

export const ADDITIONAL_SERVICE_TEST_STATUSES = {
  successfully: new Status(STATUS_TYPES.success, "Успешно", "successfully"),
  failure: new Status(STATUS_TYPES.error, "Ошибка", "failure"),
  untested: new Status(STATUS_TYPES.warning, "Тест не проводился", "untested"),
};

export const SMART_CONTRACT_TEST_STATUSES = {
  successfully: new Status(STATUS_TYPES.success, "Успешно", "successfully"),
  failure: new Status(STATUS_TYPES.error, "Ошибка", "failure"),
  untested: new Status(STATUS_TYPES.warning, "Тест не проводился", "untested"),
};
