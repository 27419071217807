<template>
  <div class="nodata">
    <img src="@/assets/images/nodata.svg" alt="no data" />
    <span>Не найдено ни одной записи!</span>
    <FormButton
      v-if="addPermission"
      label="Добавить"
      @submit="add()"
      type="button"
    />
  </div>
</template>

<script setup>
import { computed, ref, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAuthModule } from "@/store";
import { hasPermission } from "@/helpers/permissions";

const route = useRoute();
const router = useRouter();
const store = useAuthModule();
const user = computed(() => store.user);

let addPermission = ref(false);
watchEffect(() => {
  if (user.value) {
    addPermission.value = hasPermission(
      route.path,
      user.value.permissions,
      ":create"
    );
  }
});
const add = () => {
  router.push(`${route.path}/add`);
};
</script>

<style scoped lang="scss"></style>
