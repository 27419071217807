import { useAuthModule } from "@/store";

export default function guest({ next }) {
  const store = useAuthModule();
  if (store.logginIn) {
    return next({
      name: "Main",
    });
  }

  return next();
}
