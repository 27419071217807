export const permissionEnum = {
  errors: "errors",
  admins: "admins",
  staff: "staff",
  providers: "providers",
  authProviders: "providers-auth",
  authParams: "types-auth",
  serviceTypes: "types-services",
  methods: "methods",
  serviceProtocols: "protocol-services",
  services: "services",
  smartContracts: "smart-contracts",
  guides: "guides",
  docs: "docs",
  mails: "mails",
};

// eslint-disable-next-line
export const hasPermission = () => {
  return true;
};
// export const hasPermission = (route, permissions, permissionType) => {
// 	const permissionKey = Object.keys(permissionEnum).find(key => permissionEnum[key] === route.slice(1))
// 	const routePermissionKey = permissionKey + permissionType

// 	for (let i = 0; i < permissions.length; i++) {
// 		if (permissions[i].key === routePermissionKey) {
// 			return true
// 		}
// 	}

// 	return false
// }

export const permissions = {
  1: "Управление ошибками",
  2: "Управление сотрудниками",
  3: "Управление провайдерами авторизации",
  4: "Управление параметрами авторизации",
  5: "Управление провайдерами сервисов",
  6: "Управление типами сервисов",
  7: "Управление протоколами сервисов",
  8: "Управление сервисами",
  9: "Управление шаблонами писем/сообщений",
  10: "Доступ к отчетам",
};
