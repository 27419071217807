import { useRouter } from "vue-router";
import { notify } from "@kyvg/vue3-notification";
import notifications from "@/helpers/notifications";

export function useFormHandler(loading) {
  const router = useRouter();

  const handleSubmit = async ({
    form,
    progress,
    totalRequired,
    apiMethod,
    successMessage,
    redirectPath,
    id = null,
  }) => {
    loading.value = true;
    if (progress.length >= totalRequired) {
      let payload = Object.fromEntries(
        Object.entries(form).map(([key, value]) => [key, value.value])
      );
      try {
        if (id) {
          await apiMethod(payload, id);
        } else {
          await apiMethod(payload);
        }
        notify({
          type: "success",
          text: successMessage,
        });
        if (redirectPath) router.push(redirectPath);
        loading.value = false;
        return { success: true };
      } catch (err) {
        notifications(err);
        loading.value = false;
        return { success: false, error: err };
      }
    } else {
      notify({
        type: "warn",
        text: `Не заполнено ${totalRequired - progress.length} пунктов`,
      });
      loading.value = false;
      return { success: false, error: "Incomplete form" };
    }
  };

  return { handleSubmit };
}
