<template>
  <div class="page-actions">
    <div class="page-actions__tabs">
      <TabView v-model:active-index="activeTab">
        <TabPanel header="Общая информация">
          <EmployeeInfo
            :show-delete-modal="isShowDeleteModal"
            :show-edit-modal="isShowEditModal"
            :closeEditModal="closeEditModal"
            :closeDeleteModal="closeDeleteModal"
            :translation="translation"
          />
        </TabPanel>
        <TabPanel header="Роли пользователя">
          <EmployeeRoles />
        </TabPanel>
        <TabPanel header="Учетные записи">
          <EmployeeAccounts />
        </TabPanel>
      </TabView>
    </div>
    <div class="page-actions__buttons">
      <template v-if="activeTab === 0">
        <Button
          text
          size="small"
          icon="pi pi-pencil"
          rounded
          outlined
          @click="isShowEditModal = true"
        />
        <Button
          text
          size="small"
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          @click="isShowDeleteModal = true"
        />
        <Button
          rounded
          outlined
          label="Перевод"
          icon="pi pi-sort-alt"
          @click="toggleTranslation"
        />
        <Button
          rounded
          outlined
          label="Уволить"
          icon="pi pi-times"
          @click="ban"
        />
        <Button rounded label="Принять заново" icon="pi pi-history" />
      </template>

      <template v-if="activeTab === 1">
        <Button rounded outlined label="Обновить" icon="pi pi-history" />
        <Button rounded label="Назначить роль" icon="pi pi-history" />
      </template>

      <template v-if="activeTab === 2">
        <Button rounded outlined label="Обновить" icon="pi pi-history" />
        <Button rounded label="Добавить учетную запись" icon="pi pi-history" />
      </template>
    </div>
  </div>
  <router-view v-slot="{ Component }" v-if="!loading">
    <transition>
      <component :is="Component" :service="service" />
    </transition>
  </router-view>
</template>

<script setup>
import { ref } from "vue";
import Button from "primevue/button";
import EmployeeInfo from "./Tabs/EmployeeInfo.vue";
import EmployeeRoles from "./Tabs/EmployeeRoles.vue";
import EmployeeAccounts from "./Tabs/EmployeeAccounts.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import { EmployeesAPI } from "@/api/EmployeesAPI";
import { useRoute } from "vue-router";
import { notify } from "@kyvg/vue3-notification";
import notifications from "@/helpers/notifications";

const isShowDeleteModal = ref(false);
const isShowEditModal = ref(false);
const activeTab = ref(0);
const translation = ref(false);

const closeEditModal = () => {
  isShowEditModal.value = false;
};
const closeDeleteModal = () => {
  isShowDeleteModal.value = false;
};
const toggleTranslation = () => {
  translation.value = !translation.value;
};
const route = useRoute();

const ban = () => {
  EmployeesAPI.ban(route.params.id)
    .then(() => {
      notify({
        type: "success",
        text: "Пользователь уволен",
      });
    })
    .catch((err) => {
      notifications(err);
    });
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/vars";

.p-button.p-button-icon-only.p-button-rounded {
  border: 1px solid $theme200;
}
.p-button.p-button-outlined {
  background-color: #fff;
}
.p-button.p-button-text {
  color: $theme800;
}
.p-button.p-button-danger.p-button-text {
  background-color: #fff;
}
.p-button.p-button-danger.p-button-text:enabled:hover {
  background-color: #fff;
  border: 1px solid $danger;
}
.p-button.p-button-outlined {
  border: 1px solid $theme200;
  color: $theme800;
}
.p-button.p-button-outlined:enabled:hover {
  background-color: #fff;
  border: 1px solid $primary;
}
.p-button:enabled:hover {
  background: $primary;
  border: 1px solid $primary;
}
</style>
