class ValidationsForm {
  required = (val) => {
    return typeof val === "object" ? Object.values(val).length : !!val;
  };
  minLength = (num) => (val) => val.length >= num;
  maxLength = (num) => (val) => val.length <= num;

  validateAddress = (val) => {
    return /^[а-яА-ЯёЁ0-9'".,/\s-]+$/.test(val);
  };

  validateCyril = (val) => {
    return /^[а-яА-ЯёЁ'"\s-]+$/.test(val);
  };

  validateServiceType = (val) => {
    return /^[a-zA-Z0-9][a-zA-Z0-9_-]*[a-zA-Z0-9]$/.test(val.toLowerCase());
  };

  validateLatLng = (val) => {
    return String(val).match(/^-?([1-8]?[1-9]|[1-9]0)\.\d{1,6}$/);
  };

  validateURL = (val) => {
    return String(val).match(
      /^((https?:\/\/)?)(?!.*_)([\w.-]+)\.([a-z]{2,})(\/\S*)?$/i
    );
  };

  validateURLWithLocalhost = (val) => {
    return String(val).match(
      /^(https?:\/\/)?((localhost|\d{1,3}(\.\d{1,3}){3})|([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(:(?:[1-9]\d{0,4}|[1-5]\d{4}|[6][0-4]\d{3}|65535))?((?:\/[\w-]+)*)?(\/v\d+)?$/
    );
  };

  validateFullURL = (val) => {
    return String(val).match(
      /^(http:\/\/|https:\/\/)?(([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}|(\d{1,3}\.){3}\d{1,3})(:\d+)?(\/\w+){0,}(\/v\d+)?$/
    );
  };

  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
  };

  validatePhone = (phone) => {
    return String(phone).match(
      /^(\+7)?[\s-]?\(?[0-9]{3}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/
    );
  };

  validateInn = (inn) => {
    return String(inn).match(/^[\d+]{10,12}$/);
  };

  validateInnSerial = (serialInn) => {
    return String(serialInn).match(/^\d{2} \d{9}$/);
  };

  validateOgrnSerial = (serial) => {
    return String(serial).match(/^\d{2} \d{9}$/);
  };

  validateName = (name) => {
    return String(name).match(/^(?! )(?!.* $)[a-zA-Zа-яА-Я '"-]+$/);
  };

  validateKeyName = (name) => {
    return (
      String(name).match(/^[A-Za-z]+(-[A-Za-z]+)*$/) &&
      name.length >= 100 &&
      name.length <= 128
    );
  };

  validateLat = (val) => {
    return /^[a-zA-Z]+$/.test(val.toLowerCase());
  };

  validateLat = (val) => {
    return /^[a-zA-Z]+$/.test(val.toLowerCase());
  };

  validateLatOrNumbers = (val) => {
    return /^[a-zA-Z0-9]+$/.test(val.toLowerCase());
  };

  validateIp = (ip) => {
    return String(ip).match(
      /^(?!192\.|127\.)(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
    );
  };

  validateIpV6 = (ip) => {
    return String(ip).match(/^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/);
  };

  validateJSON = (input) => {
    let data;
    try {
      data = JSON.parse(input);
    } catch (e) {
      return false;
    }
    return this.validateFields(data);
  };

  validateFields = (data) => {
    for (let key in data) {
      if (typeof data[key] === "object" && data[key] !== null) {
        if (!this.validateFields(data[key])) {
          return false;
        }
      } else {
        if (key === "type") {
          if (
            !["INTEGER", "FLOAT", "CHAR", "BOOLEAN", "STRING"].includes(
              data[key]
            )
          ) {
            return false;
          }
        } else if (key === "mandatory" || key === "user") {
          if (!["True", "False"].includes(data[key])) {
            return false;
          }
        }
      }
    }
    return true;
  };
}

export default new ValidationsForm();
