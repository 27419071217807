import { ref, reactive, watch } from "vue";

const not = (val) => !val;

export function useField(field) {
  const valid = ref(true);
  const value = ref(field.value);
  const step = ref(field.step);
  const touched = ref(false);
  const errors = reactive({});
  const errorsArray = ref([]);
  const validators = reactive([]);

  const reassign = (val) => {
    valid.value = true;
    validators.splice(0, validators.length);
    Object.keys(field.validators ?? {}).map((name) => {
      const isValid = field.validators[name](val);
      errors[name] = not(isValid);
      validators.push(name);
      errorsArray.value = Object.values(errors).filter((item) => !item).length;
      if (not(isValid)) {
        valid.value = false;
      }
    });
  };

  watch(value, reassign);
  reassign(field.value);

  return {
    value,
    valid,
    step,
    errors,
    touched,
    errorsArray,
    validators,
    blur: () => (touched.value = true),
  };
}
