<template>
  <TabView @tab-change="changeRoute" :activeIndex="activeIndex">
    <TabPanel v-for="tab in tabs" :key="tab.title" :header="tab.title" />
  </TabView>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

const route = useRoute();
const router = useRouter();

const props = defineProps({
  tabs: {},
  noPanels: {
    type: Boolean,
    default: false,
  },
});
const tabs = ref(props.tabs);

const activeIndex = computed(() => {
  if (route.params) {
    return tabs.value.findIndex((tab) => tab.route === route.name);
  } else {
    return -1;
  }
});

const changeRoute = (event) => {
  const tabRoute = tabs.value[event.index].route;
  router.push({
    path: `${route.path.replace(/\/[^/]*$/, "")}/${tabRoute}`,
    query: route.query,
  });
};
</script>
