<template>
  <div class="backgraund-img"></div>
  <div class="view">
    <div class="text text-bold">
      Вы находитесь в панели Администратора Реестра сервисов.
    </div>

    <div class="text">
      Реестр сервисов – инновационный продукт являющейся частью интеграционной
      платформы ООО «Современные технологии».
    </div>

    <div class="text text-task">Задачи решаемые Реестром сервисов:</div>

    <div class="text text-bold">
      <div>
        1. Ведение сведений о сервисах (запись, редактирование, удаление).
      </div>

      <div>2. Ведение сведений о структуре сервиса:</div>
    </div>
    <div class="block-links">
      <ul>
        <li>адреса серверов, обслуживающие сервис,</li>
        <li>протоколы и способы аутентификации при обращении к сервису,</li>
        <li>используемые структуры данных запросов к сервису,</li>
        <li>используемые структуры данных ответов от сервиса,</li>
        <li>используемые методы,</li>
        <li>Smart-контракты, используемые сервисом.</li>
      </ul>
    </div>
    <div class="text text-bold">
      <div>
        3. Идентификация сервисов при помощи системы с использованием типов и
        классов сервисов.
      </div>
    </div>

    <div class="text text-bottom">
      Разрешённые действия для текущей роли включают возможности:
    </div>
    <p>
      Создание, внесение, редактирование и просмотр в Реестре сервисов данных о:
    </p>

    <div class="block-links">
      <ul>
        <li>
          <router-link class="link" to="/providers"
            >провайдерах (операторах) сервисов</router-link
          >
          в соответствии с заявками на включение в Реестр сервисов
        </li>

        <li>
          внесение в Реестр сервисов
          <router-Link class="link" to="/providers-auth">
            провайдеров авторизации</router-Link
          >
          и
          <router-Link class="link" to="/types-auth"
            >типов авторизации</router-Link
          >
        </li>
      </ul>
    </div>

    <p>Просмотра сведений о:</p>

    <div class="block-links">
      <ul>
        <li>
          <router-link class="link" to="/providers">
            провайдере (операторе) сервиса
          </router-link>
          и его <router-link class="link" to="/admins">персонале</router-link>,
          имеющий право администрирования
        </li>

        <li>
          <router-link class="link" to="/services">сервисе</router-link> – его
          наименовании и характеристике
        </li>

        <li>
          <router-link class="link" to="/providers-auth"
            >провайдере авторизации</router-link
          >, и
          <router-link class="link" to="/types-auth"
            >типе авторизации</router-link
          >
        </li>
        <li>
          <router-link class="link" to="/protocol-services"
            >протоколе</router-link
          >, который использует сервис для доступа к нему
        </li>
        <li>
          <router-link class="link" to="/types-services"
            >типе сервисов</router-link
          >
        </li>
        <li>
          <router-link class="link" to="/methods">методе </router-link>и
          <router-link class="link" to="/smart-contracts"
            >Smart-контракте </router-link
          >сервиса
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.view {
  position: absolute;
  max-width: 1256px;
  margin-left: 4%;
  padding-bottom: 50px;
  display: inline;
}
.backgraund-img {
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url("@/assets/images/bg-home.png");
  background-size: cover;
  background-position: right;
}

.text {
  width: 100%;
  max-width: 1176px;
  margin-bottom: 8px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  color: var(--light-theme-rich-black-fogra-29-tints-800, #3e445b);
  &-task,
  &-bottom {
    margin-bottom: 14px;
    width: 900px;
  }
  &-bold {
    font-weight: 700;
    font-weight: 700;
    line-height: 32px;
  }
}
p {
  margin: 8px 0 8px 0;
  color: var(--light-theme-rich-black-fogra-29-tints-800, #3e445b);
}
li {
  height: 30px;
  margin-left: 20px;
  color: var(--light-theme-rich-black-fogra-29-tints-800, #3e445b);
}
ul {
  list-style-type: disc;
  margin-left: 16px;
}

.link {
  text-decoration: none;
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--light-theme-sky-blue-crayola-tints-500, #5bc4d1);
}
</style>
