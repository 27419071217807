import ValidationsForm from "@/helpers/validations";
import { useForm } from "@/composables/form";

const { required, validateName } = ValidationsForm;

export function Form() {
  return useForm({
    name: {
      value: "",
      validators: { required, validateName },
    },
    surname: {
      value: "",
      validators: { required, validateName },
    },
    patronymic: {
      value: "",
      validators: { required, validateName },
    },
    email: {
      value: "",
      validators: { required },
    },
    birthday: {
      value: "",
      validators: { required },
    },

    departmentId: {
      value: "",
      validators: { required },
    },
    positionId: {
      value: "",
      validators: { required },
    },
  }).form;
}
