<template>
  <div class="div" @click.stop="toggle">
    <slot name="trigger">
      <i class="pi pi-angle-up" v-if="isToggle" />
      <i class="pi pi-angle-down" v-if="!isToggle" />
    </slot>
  </div>

  <OverlayPanel ref="op">
    <ul class="dropdown">
      <slot name="content" />
    </ul>
  </OverlayPanel>
</template>

<script setup>
import { ref } from "vue";
import OverlayPanel from "primevue/overlaypanel";

const op = ref();
const isToggle = ref(false);
const toggle = (event) => {
  op.value.toggle(event);
  isToggle.value = !isToggle.value;
};
</script>

<style lang="scss" scoped></style>
