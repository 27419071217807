<template>
  <div class="progress">
    <div class="progress__counter" :class="{ hidden: !total }">
      <span>{{ count }}/{{ total }}</span>
      <p>Прогресс заполнения</p>
    </div>
    <div class="progress__indicator" :class="{ hidden: !total }">
      <span :style="{ width: progress + '%' }"></span>
    </div>
    <div class="progress__buttons">
      <button type="button" @click="cancel">
        {{ cancelText }}
      </button>
      <button type="button" @click="$emit('add')">
        <div class="progress__button-loading">
          {{ actionText }}
          <Loading v-show="loading" style="height: 25px; margin: 0"></Loading>
        </div>
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from "vue";
import { useRouter } from "vue-router";
import Loading from "@/components/Loading";

const props = defineProps({
  count: {
    type: Number,
    default: 0,
  },
  total: {
    type: Number,
    default: 0,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  actionText: {
    type: String,
    default: "Добавить",
  },
  cancelText: {
    type: String,
    default: "Отмена",
  },
});

const progress = computed(() => (100 / props.total) * props.count);

const router = useRouter();
const emit = defineEmits(["cancel"]);
const cancel = () => {
  // if we will need another functionality - add check with cancelText
  emit("cancel");
  router.go(-1);
};
</script>

<style scoped lang="scss">
.progress {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  padding-left: 112px;
  padding-right: 32px;
  box-shadow: 0px 3px 6px rgb(19 23 35 / 4%), 0px 0px 2px rgb(19 23 35 / 6%),
    0px 0px 1px rgb(19 23 35 / 4%);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  height: 112px;
  &__indicator {
    flex-grow: 1;
    max-width: 650px;
    /* margin-right: auto; */
    height: 6px;
    background: #ebeef6;
    border-radius: 8px;
    position: relative;
    span {
      position: absolute;
      left: 0;
      top: 0;
      height: 6px;
      background: #5bc4d1;
      border-radius: 32px;
      transition: 0.3s;
    }
  }
  &__buttons {
    width: fit-content;
    display: flex;
    button {
      border-radius: 9px;
      width: 182px;
      height: 48px;
      color: #3e445b;
      letter-spacing: 0.3px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      &:first-child {
        background: rgba(87, 94, 118, 0.04);
      }
      &:last-child {
        background: #5bc4d1;
        color: #fff;
        margin-left: 32px;
        :disabled {
          opacity: 0;
        }
      }
    }
  }
  &__button-loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__counter {
    display: flex;
    align-items: center;
    width: fit-content;
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #3e445b;
      display: block;
      margin-right: 16px;
    }
    p {
      color: #8f97b1;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.hidden {
  visibility: hidden;
}
</style>
