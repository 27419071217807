import { createRouter, createWebHistory } from "vue-router";
import { nextTick } from "vue";
import guest from "@/middleware/guest";
import auth from "@/middleware/auth";
import permissions from "@/middleware/permissions";
import middlewarePipeline from "./middlewarePipeline";
import SignIn from "@/views/SignIn";

import Employees from "@/views/Employees/Index";
import EmployeesView from "@/views/Employees/View";
import EmployeesEdit from "@/views/Employees/Edit";
import EmployeesAdd from "@/views/Employees/Add";
import Accounts from "@/views/Accounts/Index";
import AccountsView from "@/views/Accounts/View";
import AccountsAdd from "@/views/Accounts/Add";
import AccountsEdit from "@/views/Accounts/Edit";
import Roles from "@/views/Roles/Index";
import RolesView from "@/views/Roles/View";
import RolesAdd from "@/views/Roles/Add";
import RolesEdit from "@/views/Roles/Edit";
import NoAccess from "@/views/Noaccess";
import Main from "@/views/Main/Index";
import SettingsTab from "@/views/Roles/Tabs/SettingsTab.vue";

const routes = [
  {
    path: "/main",
    name: "main",
    component: Main,
    meta: {
      layout: "DefaultLayout",
      title: "Добро пожаловать в CTfind IDM!",
      middleware: [auth],
      breadcrumb: "Главная",
    },
  },
  {
    path: "/signin",
    name: "login",
    component: SignIn,
    meta: {
      layout: "AuthLayout",
      title: "Авторизация",
      middleware: [guest],
    },
  },

  // Employees
  {
    path: "/employees",
    name: "employees",
    component: Employees,
    meta: {
      breadcrumb: "Пользователи",
      addPath: "/employees/add",
      layout: "DefaultLayout",
      permission: "employees:list",
      title: "Пользователи",
      middleware: [auth, permissions],
    },
  },
  {
    path: "/employees/:id",
    name: "employeesView",
    component: EmployeesView,
    meta: {
      breadcrumb: "Просмотр пользователя",
      addPath: "/employees/add",
      layout: "DefaultLayout",
      permission: "employees:edit",
      title: "Карточка пользователя",
      middleware: [auth, permissions],
    },
  },
  {
    path: "/employees/edit/:id",
    name: "employeesEdit",
    component: EmployeesEdit,
    meta: {
      breadcrumb: "Редактирование пользователя",
      addPath: "/employees/add",
      layout: "DefaultLayout",
      title: "Пользователи",
      permission: "employees:edit",
      middleware: [auth, permissions],
    },
  },
  {
    path: "/employees/add",
    name: "employeesAdd",
    component: EmployeesAdd,
    meta: {
      breadcrumb: "Добавление пользователя",
      addPath: "/employees/add",
      layout: "DefaultLayout",
      permission: "employees:create",
      title: "Добавить пользователя",
      middleware: [auth, permissions],
    },
  },

  // Accounts
  {
    path: "/accounts",
    name: "accounts",
    component: Accounts,
    meta: {
      breadcrumb: "Учетные записи",
      addPath: "/accounts/add",
      layout: "DefaultLayout",
      title: "Учетные записи",
      permission: "accounts:list",
      middleware: [auth, permissions],
    },
  },
  {
    path: "/accounts/:id",
    name: "accountsView",
    component: AccountsView,
    meta: {
      breadcrumb: "Просмотр учетной записи",
      addPath: "/accounts/add",
      layout: "DefaultLayout",
      title: "Учетная запись",
      permission: "accounts:view",
      middleware: [auth, permissions],
    },
  },
  {
    path: "/accounts/edit/:id",
    name: "accountsEdit",
    component: AccountsEdit,
    meta: {
      breadcrumb: "Редактирование учетной записи",
      addPath: "/accounts/add",
      layout: "DefaultLayout",
      title: "Редактировать учетную запись",
      permission: "accounts:edit",
      middleware: [auth, permissions],
    },
  },
  {
    path: "/accounts/add",
    name: "accountsAdd",
    component: AccountsAdd,
    meta: {
      breadcrumb: "Добавление учетной записи",
      addPath: "/accounts/add",
      layout: "DefaultLayout",
      title: "Добавить учетную запись",
      permission: "accounts:create",
      middleware: [auth, permissions],
    },
  },
  // Roles
  {
    path: "/roles",
    name: "roles",
    component: Roles,
    meta: {
      breadcrumb: "Управление ролями",
      addPath: "/roles/add",
      layout: "DefaultLayout",
      permission: "roles:list",
      title: "Управление ролями",
      middleware: [auth, permissions],
    },
  },
  {
    path: "/roles/add",
    name: "rolesAdd",
    component: RolesAdd,
    meta: {
      breadcrumb: "Добавить роль",
      addPath: "/roles/add",
      layout: "DefaultLayout",
      permission: "roles:create",
      title: "Добавить сервис",
      middleware: [auth, permissions],
    },
  },
  {
    path: "/roles/:id",
    name: "rolesView",
    component: RolesView,
    redirect: { name: "settings" },
    meta: {
      breadcrumb: "Просмотр сервиса",
      addPath: "/roles/add",
      layout: "DefaultLayout",
      permission: "roles:view",
      middleware: [auth, permissions],
    },
    children: [
      {
        path: "settings",
        name: "settings",
        component: SettingsTab,
        meta: {
          breadcrumb: "Общие настройки",
        },
      },
      {
        path: "source",
        name: "source",
        component: () => import("@/views/Roles/Tabs/SourceTab.vue"),
        meta: {
          breadcrumb: "Источник",
        },
      },
      {
        path: "roles-employees",
        name: "roles-employees",
        component: () => import("@/views/Roles/Tabs/EmployeesTab.vue"),
        meta: {
          breadcrumb: "Пользователи",
        },
      },
    ],
  },
  {
    path: "/roles/edit/:id",
    name: "rolesEdit",
    component: RolesEdit,
    meta: {
      breadcrumb: "Редактирование роли",
      addPath: "/roles/add",
      layout: "DefaultLayout",
      permission: "roles:edit",
      middleware: [auth, permissions],
    },
    children: [
      {
        path: "settings",
        name: "edit-settings",
        component: () => import("@/views/Roles/Forms/RolesSettings.vue"),
        meta: {
          breadcrumb: "Редактирование роли",
        },
      },
      {
        path: "source",
        name: "edit-source",
        component: () => import("@/views/Roles/Tabs/SourceTab.vue"),
        meta: {
          breadcrumb: "Источник",
        },
      },
      {
        path: "roles-employees",
        name: "edit-employees",
        component: () => import("@/views/Roles/Tabs/EmployeesTab.vue"),
        meta: {
          breadcrumb: "Пользователи",
        },
      },
    ],
  },
  {
    path: "/noaccess",
    name: "noaccess",
    component: NoAccess,
    meta: {
      layout: "DefaultLayout",
      middleware: [auth],
    },
  },
  { path: "/:catchAll(.*)", redirect: "/employees" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }
  const middleware = to.meta.middleware;
  const context = {
    to,
    from,
    next,
  };
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

router.afterEach(() => {
  nextTick(() => {
    document.title = "CTfind IDM";
  });
});

export default router;
