import ValidationsForm from "@/helpers/validations";
import { useForm } from "@/composables/form";

const { required } = ValidationsForm;

export function AddEmployeeForm() {
  return useForm({
    name: {
      value: "",
      validators: { required },
    },
    subdivision: {
      value: "",
      validators: { required },
    },
    job: {
      value: "",
      validators: { required },
    },
  }).form;
}

export const apiKeyData = useForm({
  key: {
    value: "",
    validators: { required },
  },
  value: {
    value: "",
    validators: { required },
  },
});

export const bearerData = useForm({
  login: {
    value: "",
    validators: { required },
  },
  password: {
    value: "",
    validators: { required },
  },
});

export const bearerToken = useForm({
  token: {
    value: "",
    validators: { required },
  },
});
