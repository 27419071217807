<template>
  <div class="advanced-filter">
    <div class="filter-header">
      <svg viewBox="0 0 16 16" class="v-icon cancel" @click="closeFilter">
        <use href="#cancel" />
      </svg>
      <h3>Расширенный фильтр</h3>
    </div>
    <div class="filter-body">
      <div v-for="col in filterableColumns" :key="col.title">
        <div class="filter-section">
          <label class="label" :for="col.title">{{ col.title }}</label>
          <span
            class="arrow"
            @click="col.push({ open: true })"
            :class="{ open: col.open }"
          >
            <svg
              width="7px"
              height="13px"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              class="v-icon"
            >
              <use href="#arrow" />
            </svg>
          </span>
        </div>
        <div class="checkbox-container" v-for="item in col.data" :key="item">
          <div class="checkbox item" style="width: max-content">
            <!-- wait backend -->
            <input
              type="checkbox"
              :id="item"
              :checked="!col.data"
              @change="
                updateFilterModel(
                  filters[col.field],
                  item,
                  $event.target.checked
                )
              "
            />

            <label :for="item" class="source-name">
              {{ item }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="filter-footer">
      <Button
        label="Очистить"
        class="cancel-button button"
        @click="clearFilters"
      />
      <Button
        label="Применить фильтр"
        class="apply-button button"
        @click="applyFilters"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits } from "vue";
import Button from "primevue/button";

const props = defineProps({
  filterableColumns: {
    type: Array,
    default: () => [],
  },
  filters: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["update:filters", "close"]);

const applyFilters = () => {
  emit("update:filters", { ...filters.value });
};

const clearFilters = () => {
  for (const key in filters.value) {
    filters.value[key].value = null;
  }
};

const closeFilter = () => {
  emit("close");
};

const filters = ref({ ...props.filters });

const updateFilterModel = (filterModel, item, isChecked) => {
  if (!Array.isArray(filterModel.value)) {
    filterModel.value = [];
  }
  const index = filterModel.value.indexOf(item);
  if (isChecked && index === -1) {
    filterModel.value.push(item);
  } else if (!isChecked && index !== -1) {
    filterModel.value.splice(index, 1);
  }
};

watch(
  () => props.filters,
  (newFilters) => {
    filters.value = { ...newFilters };
  },
  { deep: true }
);
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars";

.advanced-filter {
  position: fixed;
  right: 0;
  top: 101px;
  width: 482px;
  height: calc(100vh - 101px);
  background: #f9fbff;
  box-shadow: -2px 0px 32px 0px #0000000f;
  border-radius: 24px;
  z-index: 9;
  color: $theme900;

  .filter-header {
    position: absolute;
    top: 16px;
    width: 482px;
    padding: 16px 32px;
    display: flex;
    flex-direction: column; /* Разместить заголовок и кнопку в столбец */
    align-items: flex-start; /* Выровнять по левому краю */
    font-size: 28px;
    gap: 10px; /* Задаем расстояние между кнопкой и заголовком */

    .cancel {
      width: 32px;
      padding: 10px;
      background-color: #fff;
      border-radius: 9px;
      cursor: pointer;
      margin-left: auto;
    }
  }

  .filter-body {
    position: absolute;
    top: 128px;
    left: 32px;
    padding: 8px 0;
    overflow: scroll;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .filter-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 418px;
      padding-bottom: 8px;
      border: 1px dotted #d4dbe8 !important;
      border-width: 0 0 1px 0 !important;
      .label {
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        color: $theme900;
      }
      .arrow {
        cursor: pointer;
        margin-left: auto;
        transform: rotate(90deg);
        transition: transform 0.3s;
        &.open {
          transform: rotate(180deg);
        }
      }
    }
    .checkbox-container {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin-top: 24px;
    }
  }

  .filter-footer {
    position: absolute;
    bottom: 0;
    padding: 32px;
    width: 482px;
    height: 112px;
    gap: 10px;
    box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.04);
    border-radius: 0 0 0 24px;
    background: #f9fbff;
    justify-content: space-between;
  }
  .filter-footer button {
    border-radius: 48px;
    font-size: 16px;
  }
}
</style>
