<template>
  <div class="source-component">
    <div class="columns">
      <div class="left-column">
        <div v-for="source in sources" :key="source.id" class="source-item">
          <div class="checkbox" style="width: max-content">
            <input type="checkbox" v-model="source.checked" :id="source.id" />
            <label :for="source.id" class="source-name">
              {{ source.name }}
            </label>
          </div>
          <span
            class="arrow"
            @click="toggleSource(source)"
            :class="{ open: source.open }"
          >
            <svg
              width="7px"
              height="13px"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              class="v-icon"
            >
              <use href="#arrow" />
            </svg>
          </span>
        </div>
      </div>
      <div class="right-column" v-if="selectedSource">
        <h3 class="list-header">Список прав доступа</h3>
        <div class="rights-container">
          <ul
            class="list-box"
            v-for="(rightsCategory, index) in Object.values(
              selectedSource.rights
            )"
            :key="index"
          >
            <li class="list-item" v-for="right in rightsCategory" :key="right">
              {{ right }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";

const props = defineProps({
  sources: Array,
});

const emit = defineEmits(["update"]);

const selectedSource = ref(null);

const toggleSource = (source) => {
  props.sources.forEach((s) => {
    if (s.id !== source.id) {
      s.open = false;
    }
  });
  source.open = !source.open;
  selectedSource.value = source.open ? source : null;
  emit("update", props.sources);
};
</script>

<style scoped lang="scss">
.source-component {
  .columns {
    display: flex;
    border: 1px solid #ebeef6;
    background: #575e7608;
    border-radius: 9px;
    width: 100%;
    padding: 24px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    // min-height: 772px;
  }
  .left-column {
    width: 49%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .source-item {
      display: flex;
      align-items: center;
      border: 1px solid #ddd;
      border-radius: 9px;
      background: #ffffff;
      height: 80px;
      gap: 24px;
      padding: 24px;
      .source-name {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 700;
        padding: 4px 0px 4px 60px;
      }
      .arrow {
        cursor: pointer;
        margin-left: auto;
        transition: transform 0.3s;
        &.open {
          transform: rotate(180deg);
        }
      }
    }
  }
  .right-column {
    width: 49%;
    border: 1px solid #ddd;
    border-radius: 9px;
    background: #ffffff;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    .list-header {
      font-size: 18px;
      font-weight: 700;
      border-bottom: 1px solid #ebeef6;
      padding: 24px;
      height: 80px;
      width: 100%;
      display: flex;
      align-items: center;
    }
    .rights-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .list-box {
      display: flex;
      gap: 24px;
      padding: 24px;
      flex-direction: column;
      position: relative;
      & + .list-box::before {
        content: "";
        position: absolute;
        top: 0;
        left: 5%;
        height: 1px;
        width: 90%;
        background-color: #ebeef6;
      }
    }
    .list-item {
      font-size: 18px;
      line-height: 28px;
      text-align: left;
      color: #727a93;
    }
  }
}
</style>
